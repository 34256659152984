.ResutlsFor {
  font-weight: 900;
  color: #ffffff;

  span {
    color: #3BB1E6;
  }

  .ClearButton {
    color: var(--bs-secondary);
    font-size: 18pt;
    margin-left: 1rem;
  }
}
