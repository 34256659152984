.ContionusAuthentication {
  padding-top: 3rem;
  background-image: url('../../images/contact-form-ci-bg.svg');
  background-position: top center;
  background-repeat: no-repeat;

  @media only screen and (max-width: 767.98px) {
    padding-top: 1rem;
  }

  .Intro {
    .Card {
      background-color: #132735;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.141176);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 14rem;
      margin: 0 auto 3rem;
      padding: 2.5rem 1.5rem;
      border-radius: 3px;

      img {
        margin: 0;
      }
    }

    p {
      text-align: center;
      color: #ffffff;
      font-size: 26px;

      strong {
        color: #FFCE48;
      }

      a {
        color: #ffffff;
        font-weight: normal;
      }
    }
  }

  .Image {
    @media only screen and (max-width: 767.98px) {
      max-width: 85%;
      margin-bottom: 2.5rem;
    }
  }

  .Title {
    font-size: 54px;
    font-weight: 800;
    color: #FFCE48;
    text-align: center;
    margin: 3rem 0;
  }

  .Reverse {
    div {
      &:first-child {
        @media only screen and (max-width: 767.98px) {
          order: 2;
        }
      }

      &:last-child {
        @media only screen and (max-width: 767.98px) {
          order: 1;
        }
      }
    }
  }

  .Ordered {
    div {
      &:nth-child(1) {
        @media only screen and (max-width: 767.98px) {
          order: 3;
        }
      }

      &:nth-child(2) {
        @media only screen and (max-width: 767.98px) {
          order: 1;
        }
      }

      &:nth-child(3) {
        @media only screen and (max-width: 767.98px) {
          order: 2;
        }
      }
    }
  }

  .Element {
    iframe {
      @media only screen and (max-width: 767.98px) {
        width: 100%;
        height: 13rem;
      }
    }

    h2 {
      font-size: 54px;
      font-weight: 800;
      color: #FFCE48;
    }

    p {
      font-size: 20px;
      color: #ffffff;

      a {
        color: #ffffff;
        font-weight: normal;
      }
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .Steps {
    display: flex;

    @media only screen and (max-width: 767.98px) {
      display: block;
    }

    .Step {
      width: 22%;
      text-align: center;
      background-color: #132735;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.141176);
      margin: 0 auto 3rem;
      padding: 1.5rem;
      border-radius: 3px;

      @media only screen and (max-width: 767.98px) {
        width: 70%
      }

      h5 {
        font-size: 20px;
        color: #FFCE48;
        font-weight: 700;
      }

      p {
        color: #ffffff;
        margin: 0;
      }

      hr {
        background-color: #ffffff;
      }

      .Green {
        font-weight: 300;
        color: #31CB2E;
        text-shadow:
            0 0 1px #31CB2E,
            0 0 3px #31CB2E,
            0 0 9px #31CB2E,
      }

      .Red {
        font-weight: 300;
        color: #DF2424;
        text-shadow:
            0 0 1px #DF2424,
            0 0 3px #DF2424,
            0 0 9px #DF2424,
      }
    }
  }

  .Results {
    text-align: center;

    .ResultItem {
      background-color: #132735;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.141176);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 14rem;
      padding: 1.5rem;
      margin: 0 3rem;
      border-radius: 3px;

      @media only screen and (max-width: 767.98px) {
        margin: 0 auto 2rem;
      }
    }

    h2 {
      font-size: 54px;
      font-weight: 800;
      color: #FFCE48;
    }

    h3 {
      font-size: 46px;
      font-weight: 800;
      color: #FFCE48;
    }

    p {
      font-size: 20px;
      color: #ffffff;
    }

    span {
      font-size: 14px;
      color: #ffffff;
    }
  }
}
