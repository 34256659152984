.accordion {
  display: flex;
  gap: 10px;

  .accordion-item {
    cursor: pointer;
    transition: all 0.3s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 500px;
    position: relative;
    background: #132735;
    box-shadow: 0px 2px 30px 3px rgba(0, 0, 0, 0.197279);
    border-radius: 4px;
    padding: 2rem;
    color: white;
    flex-basis: 10%;

    &.active {
      flex-basis: 80%;
    }

    &:not(.active) {
      justify-content: flex-start;

      .accordion-title {
        font-size: 28px;
        color: #ffffff;
        font-weight: 800;
        width: 30rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform-origin: center center;
        transform: translate(-50%, -50%) rotate(-90deg);
      }

      .accordion-content {
        max-height: 0;
        opacity: 0;
      }
    }

    &.active {
      justify-content: flex-start;

      .accordion-title {
        transform: rotate(0);
        font-size: 28px;
        font-weight: 800;
        position: static;
        transform: none;
        color: #3FC5F0;
      }

      .accordion-content {
        opacity: 1;
      }
    }

    .accordion-title {
      font-size: 28px;
      font-weight: 800;
      padding: 10px;
      text-align: center;
      line-height: 25px;
    }

    .accordion-content {
      padding: 10px;
      opacity: 1;

      h3 {
        font-weight: 800;
        font-size: 18px;
        line-height: 23px;
        color: #FFCE48;
        margin: 0;
      }

      h4 {
        font-size: 18px;
        margin: 0;
      }

      p {
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        margin: 0;
      }

      ul {
        margin: 1rem 0 0;
        padding: 0 0 0 1.25rem;
      }

      li {
        color: #ffffff;
        display: block;
        font-size: 20px;
        font-weight: 300;
        line-height: 28px;
        margin-bottom: 12px;
        position: relative;

        &:before {
          content: '';
          background-image: url('../../../../images/list-arrow.svg');
          background-size: 100%;
          background-repeat: no-repeat;
          width: 10px;
          height: 18px;
          display: block;
          position: absolute;
          left: -22px;
          top: 7px;
        }
      }

      .grey {
        color: #c3c3c3;
      }
    }
  }

  .hexagon {
    min-height: 103px;
  }

  .hexagon-left,
  .hexagon-right {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    color: #ffffff;
    display: flex;
    align-items: center;
  }

  .hexagon-left {
    padding: 20px 20px 20px 40px;
    clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 10% 100%, 0% 50%);
    background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(42, 60, 87, 0.4) 100%), linear-gradient(135deg, #1D3241 0%, #092756 100%);
  }

  .hexagon-right {
    padding: 20px 40px 20px 20px;
    clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
    background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(42, 60, 87, 0.4) 100%, rgba(104, 128, 138, 0.4) 10%), linear-gradient(135deg, #092756 0%, #1D3241 100%);
    background-size: 100%;
  }

  &.only-desktop {
    @media only screen and (max-width: 575.98px) {
      display: none;
    }
  }
}

.mobiletable {
  width: 100%;
  margin: 0;

  .nav-item {
    width: 33%;
  }

  .nav-tabs {
    border: 0;
    padding: 0;

    .nav-link {
      width: 100%;
      border: 0;
      border-radius: 0;
      color: #ffffff;
      font-weight: 700;
      font-size: 22px;
      line-height: 29px;
      padding: 25px 5px;
      background: #11202a;

      &.active {
        color: #000000;
        background: linear-gradient(127.56deg, #73E4F9 14.09%, #3FC5F0 90.83%);
      }
    }
  }

  .tab-content {
    background: #132735;
    box-shadow: 0px 2px 30px 3px rgba(0, 0, 0, 0.197279);
    border-radius: 4px;
    padding: 2rem;

    .active {
      @media only screen and (max-width: 767.98px) {
        display: block;
      }
    }
  }
}
