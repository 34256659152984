.Solutions {
  .SolutionsCard {
    background-color: #132735;
    border-radius: 5px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.141176);
    height: 100%;

    h3 {
      color: #FFCE48;
      font-weight: 700;
    }

    a {
      text-decoration: none;
      color: #ffffff;
      font-size: 21px;
      font-weight: 600;

      &:hover {
        color: #3BB1E6;

        span {
          color: #d2d2d2;
        }
      }

      span {
        display: block;
        color: #d2d2d2;
        font-size: 16px;
        font-weight: 400;
      }
    }

    .Biometrics {
      height: 100%;
      padding: 50px;
      background: #132735 url('../../images/logo-small-signo.svg') 120% 105%;
      background-size: 20rem;
      background-repeat: no-repeat;

      @media only screen and (max-width: 991.98px) {
        background-size: 80%;
        background-position: 50% -25%;
      }
    }

    .Cybersecurity {
      height: 100%;
      padding: 50px;
      background: #132735 url('../../images/logo-small-graboxy.svg') 110% 125%;
      background-size: 16rem;
      background-repeat: no-repeat;

      @media only screen and (max-width: 991.98px) {
        background-size: 80%;
        background-position: 50% -25%;
      }
    }

    .Motion {
      height: 100%;
      padding: 50px;
      background: #132735 url('../../images/logo-small-motionanalysis.svg') 110% 105%;
      background-size: 20rem;
      background-repeat: no-repeat;

      @media only screen and (max-width: 991.98px) {
        background-size: 80%;
        background-position: 50% -25%;
      }
    }

    .Cheat {
      height: 100%;
      padding: 50px;
      background: #132735 url('../../images/logo-small-anticheat.svg') 100% -40%;
      background-size: 18rem;
      background-repeat: no-repeat;

      @media only screen and (max-width: 991.98px) {
        background-size: 80%;
        background-position: 50% -25%;
      }
    }
  }
}
