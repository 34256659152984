.GetInTouch {
  display: flex;
  background-image:
    repeating-linear-gradient(0deg, #43BC96, #43BC96 4px, transparent 4px, transparent 30px, #43BC96 30px),
    repeating-linear-gradient(90deg, #43BC96, #43BC96 4px, transparent 4px, transparent 30px, #43BC96 30px),
    repeating-linear-gradient(180deg, #43BC96, #43BC96 4px, transparent 4px, transparent 30px, #43BC96 30px),
    repeating-linear-gradient(270deg, #43BC96, #43BC96 4px, transparent 4px, transparent 30px, #43BC96 30px);
  background-size: 3px 100%, 100% 3px, 3px 100% , 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  padding: 53px 85px;

  @media only screen and (max-width: 767.98px) {
    display: block;
  }

  @media only screen and (max-width: 575.98px) {
    width: 90%;
    margin: 0 auto;
    padding: 15px;
  }

  .GetInTouchInner {
    position: relative;

    &:first-child {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      width: 50%;
      z-index: 5;

      @media only screen and (max-width: 767.98px) {
        width: 100%;
        clip-path: none;
        padding: 24px 16px;
      }

      a {
        @media only screen and (max-width: 575.98px) {
          margin: 0 auto;
        }
      }
    }

    &:last-child {
      width: 50%;
      color: #ffffff;

      @media only screen and (max-width: 767.98px) {
        width: 100%;
        clip-path: none;
        position: static;
        margin: 0;
        padding: 24px 16px;
      }
    }

    h3 {
      font-weight: 800;
      font-size: 48px;
      line-height: 120%;
      color: #FFCE48;
      margin-bottom: 2rem;

      @media only screen and (max-width: 1189.98px) {
        font-size: 34px;
      }

      @media only screen and (max-width: 575.98px) {
        font-size: 26px;
        text-align: center;
      }
    }

    ul {
      padding: 0;
      margin: 0;

      @media only screen and (max-width: 767.98px) {
        padding-left: 30px;
      }
    }

    li {
      margin-bottom: 45px;
      line-height: 125%;
      list-style: none;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '';
        background-image: url('../../images/list-item.svg');
        background-size: 100%;
        width: 12px;
        height: 16px;
        display: block;
        position: absolute;
        left: -22px;
        top: 7px;
      }

      @media only screen and (max-width: 1189.98px) {
        margin-bottom: 25px;
      }

      @media only screen and (max-width: 575.98px) {
        font-size: 19px;
      }
    }

    [class~=ButtonDark] {
      @media only screen and (max-width: 767px) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
