.BaseString {
  clip-path: polygon(46% 0%, 54% 0%, 54% 100%, 46% 100%);
  height: 48px;
  position: absolute;
  width: 48px;
  left: calc(50% - 24px);
  top: -24px;
}

.Cybersecurity {
  @extend .BaseString;
}

.String1 {
  @extend .Cybersecurity;

  transform: translate(-610px, 0px) rotate(-90deg);
  background: linear-gradient(0deg, rgba(67, 188, 150, 1) 0%, rgba(255,255,255,0) 100%);

  @media only screen and (max-width: 991px) {
    transform: translate(-290px, -276px) rotate(-90deg);
  }
}

.String2 {
  @extend .Cybersecurity;

  transform: translate(-256px, -202px) rotate(30deg);
  background: linear-gradient(0deg, rgba(67, 188, 150, 1) 0%, rgba(255,255,255,0) 100%);

  @media only screen and (max-width: 991px) {
    transform: translate(64px, -478px) rotate(30deg);
  }
}

.String3 {
  @extend .Cybersecurity;

  transform: translate(-96px, -112px) rotate(30deg);
  background: linear-gradient(0deg, rgba(67, 188, 150, 1) 0%, rgba(255,255,255,0) 100%);

  @media only screen and (max-width: 991px) {
    transform: translate(226px, -388px) rotate(30deg);
  }
}

.String4 {
  @extend .Cybersecurity;

  transform: translate(-224px, 112px) rotate(30deg);
  background: linear-gradient(180deg, rgba(67, 188, 150, 1) 0%, rgba(255,255,255,0) 100%);

  @media only screen and (max-width: 991px) {
    transform: translate(96px, -166px) rotate(30deg);
  }
}

.String5 {
  @extend .Cybersecurity;

  transform: translate(-96px, 112px) rotate(-30deg);
  background: linear-gradient(180deg, rgba(67, 188, 150, 1) 0%, rgba(255,255,255,0) 100%);

  @media only screen and (max-width: 991px) {
    transform: unset;
    display: none;
  }
}

.Neurology {
  @extend .BaseString;

  background-color: #A079D2;
}

.String6 {
  @extend .Neurology;

  transform: translate(32px, -182px) rotate(-90deg);
  background: linear-gradient(0deg, rgba(67, 188, 150, 1) 0%, rgba(255,255,255,0) 100%);

  @media only screen and (max-width: 991px) {
    transform: translate(130px, 0px) rotate(90deg);
  }
}

.String7 {
  @extend .Neurology;

  transform: translate(224px, -72px) rotate(-30deg);
  background: linear-gradient(180deg, rgba(67, 188, 150, 1) 0%, rgba(255,255,255,0) 100%);

  @media only screen and (max-width: 991px) {
    transform: translate(-66px, 112px) rotate(30deg);
  }
}

.Biometrics {
  @extend .BaseString;

  background-color: #3CB0E6;
}

.String8 {
  @extend .Biometrics;

  transform: translate(96px, 72px) rotate(-30deg);
  background: linear-gradient(0deg, rgba(67, 188, 150, 1) 0%, rgba(255,255,255,0) 100%);

  @media only screen and (max-width: 991px) {
    transform: translate(-226px, 166px) rotate(-30deg);
  }
}

.String9 {
  @extend .Biometrics;

  transform: translate(386px, -20px) rotate(30deg);
  background: linear-gradient(0deg, rgba(67, 188, 150, 1) 0%, rgba(255,255,255,0) 100%);

  @media only screen and (max-width: 991px) {
    transform: unset;
    display: none;
  }
}

.String10 {
  @extend .Biometrics;

  transform: translate(544px, 72px) rotate(30deg);
  background: linear-gradient(0deg, rgba(67, 188, 150, 1) 0%, rgba(255,255,255,0) 100%);

  @media only screen and (max-width: 991px) {
    transform: unset;
    display: none;
  }
}

.String11 {
  @extend .Biometrics;

  transform: translate(224px, 294px) rotate(-30deg);
  background: linear-gradient(180deg, rgba(67, 188, 150, 1) 0%, rgba(255,255,255,0) 100%);

  @media only screen and (max-width: 991px) {
    transform: unset;
    display: none;
  }
}

.String12 {
  @extend .Biometrics;

  transform: translate(416px, 294px) rotate(30deg);
  background: linear-gradient(180deg, rgba(67, 188, 150, 1) 0%, rgba(255,255,255,0) 100%);

  @media only screen and (max-width: 991px) {
    transform: translate(-64px, 480px) rotate(30deg);
  }
}
