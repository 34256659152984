.SensorVideo {
  margin-bottom: 3rem;
  background-image: url('../../images/hexa-bg.svg');
  background-position: top center;
  background-repeat: no-repeat;

  .MovementSensorItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8rem;

    @media only screen and (max-width: 767.98px) {
      flex-direction: column-reverse;
      margin-bottom: 8rem;
    }

    &:last-child {
      @media only screen and (max-width: 767.98px) {
        margin-bottom: 2rem;
      }
    }

    .MovementSensorItemImg {
      position: relative;
      width: 70%;

      @media only screen and (max-width: 1400px) {
        width: 65%;
      }

      @media only screen and (max-width: 767.98px) {
        width: 100%;
      }

      img {
        display: block;
        width: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        bottom: -8px;
        right: -8px;
        padding: 4px;
        background: #3BB1E6;
        -webkit-mask:
           linear-gradient(#fff 0 0) content-box,
           linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;

        @media only screen and (max-width: 1480px) {
          right: -10px;
        }

        @media only screen and (max-width: 1400px) {
          width: 93%;
          right: -8px;
        }

        @media only screen and (max-width: 1199.98px) {
          right: -8px;
        }
      }
    }

    .MovementSensorItemText {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35%;
      margin-left: -5%;
      position: relative;
      z-index: 10;

      @media only screen and (max-width: 1400px) {
        width: 40%;
      }

      @media only screen and (max-width: 767.98px) {
        width: 100%;
        margin: 0 auto -5%;
      }

      @media only screen and (max-width: 575.98px) {
        margin: 0 auto 5%;
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0 5.25rem 0 3.75rem;
        width: 330px;
        height: 250px;
        background: transparent url('../../images/hexa_bg.svg') no-repeat center;
        background-size: 100%;

        @media only screen and (max-width: 767.98px) {
          height: 260px;
        }
      }

      p {
        margin: 0;
        font-size: 15px;

        @media only screen and (max-width: 1199.98px) {
          font-size: 13px;
        }

        @media only screen and (max-width: 767.98px) {
          font-size: 16px;
        }
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      @media only screen and (max-width: 767.98px) {
        flex-direction: column-reverse;
      }

      .MovementSensorItemText {
        margin-left: 0;
        margin-right: -5%;

        @media only screen and (max-width: 767.98px) {
          margin-right: unset;
          margin-bottom: -5%;
        }

        @media only screen and (max-width: 575.98px) {
          margin: 0 auto 5%;
        }

        div {
          padding: 0 3.75rem 0 5.25rem;
          background: transparent url('../../images/hexa_bg_swap.svg') no-repeat center;
          background-size: 100%;
        }
      }

      .MovementSensorItemImg {
        &:after {
          bottom: unset;
          right: unset;
          top: -8px;
          left: -8px;
        }
      }
    }
  }

  .GetInTouch {
    display: flex;
    max-width: 1170px;
    margin: 0 auto 4rem;
    padding: 42px 85px;
    background-image: repeating-linear-gradient(0deg, #43BC96, #43BC96 4px, transparent 4px, transparent 30px, #43BC96 30px), repeating-linear-gradient(90deg, #43BC96, #43BC96 4px, transparent 4px, transparent 30px, #43BC96 30px), repeating-linear-gradient(180deg, #43BC96, #43BC96 4px, transparent 4px, transparent 30px, #43BC96 30px), repeating-linear-gradient(270deg, #43BC96, #43BC96 4px, transparent 4px, transparent 30px, #43BC96 30px);
    background-size: 3px 100%, 100% 3px, 3px 100% , 100% 3px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 767.98px) {
      display: block;
    }

    @media only screen and (max-width: 575.98px) {
      width: 90%;
      margin: 0 auto;
      padding: 15px;
    }

    .GetInTouchInner {
      position: relative;

      &:first-child {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 50%;
        z-index: 5;

        @media only screen and (max-width: 767.98px) {
          width: 100%;
          clip-path: none;
          padding: 24px 16px;
        }

        a {
          @media only screen and (max-width: 575.98px) {
            display: block;
            margin: 0 auto;
          }
        }
      }

      &:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40%;
        margin-left: 10%;
        background: #1D3241;
        color: #ffffff;

        @media only screen and (max-width: 767.98px) {
          width: 100%;
          clip-path: none;
          position: static;
          margin: 0;
          padding: 24px 16px;
        }
      }

      h3 {
        font-weight: 800;
        font-size: 48px;
        line-height: 120%;
        color: #FFCE48;
        margin-bottom: 2rem;

        @media only screen and (max-width: 575.98px) {
          font-size: 28px;
          text-align: center;
        }
      }

      ul {
        padding: 0;
        margin: 0;

        @media only screen and (max-width: 767.98px) {
          padding-left: 30px;
        }
      }

      li {
        margin-bottom: 45px;
        line-height: 125%;
        list-style: none;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        position: relative;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: '';
          background-image: url('../../images/list-item.svg');
          background-size: 100%;
          width: 12px;
          height: 16px;
          display: block;
          position: absolute;
          left: -22px;
          top: 7px;
        }

        @media only screen and (max-width: 575.98px) {
          font-size: 18px;
          line-height: 150%;
          margin-bottom: 30px;
        }
      }

      [class~=ButtonDark] {
        @media only screen and (max-width: 767px) {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .videoContainer {
    position: relative;

    iframe {
      width: 100%;
      margin: 30px 0 50px;
      height: 30rem;
      position: relative;
      z-index: 50;

      @media only screen and (max-width: 991.98px) {
        height: 20rem;
      }

      @media only screen and (max-width: 767.98px) {
        height: 13rem;
        margin: 0;
      }
    }

    &:before {
      content: '';
      position: absolute;
      background: #3BB1E6;
      width: 50rem;
      height: 28rem;
      z-index: 10;
      top: 20px;
      right: -8px;

      @media only screen and (max-width: 1480px) {
        right: -8px;
      }

      @media only screen and (max-width: 1400px) {
        width: 45rem;
        height: 25rem;
        right: -8px;
        top: 3.25rem;
      }

      @media only screen and (max-width: 1199.98px) {
        width: 37rem;
        height: 21rem;
        right: -0.5rem;
        top: 5.25rem;
      }

      @media only screen and (max-width: 992px) {
        width: 28rem;
        height: 15.5rem;
        right: -0.5rem;
        top: 3.25rem;
      }

      @media only screen and (max-width: 768px) {
        width: 26rem;
        height: 15.5rem;
        right: 2.25rem;
        top: 1rem;
      }

      @media only screen and (max-width: 575.98px) {
        display: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 50rem;
      height: 28rem;
      z-index: 10;
      bottom: 45px;
      left: -10px;
      padding: 2px;
      background: #3BB1E6;
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;

      @media only screen and (max-width: 1480px) {
        left: -10px;
      }

      @media only screen and (max-width: 1400px) {
        width: 45rem;
        height: 25rem;
        left: -8px;
        bottom: 5rem;
      }

      @media only screen and (max-width: 1199.98px) {
        width: 38rem;
        height: 21rem;
        left: -0.75rem;
        bottom: 6.85rem;
      }

      @media only screen and (max-width: 992px) {
        width: 28rem;
        height: 15rem;
        left: -0.75rem;
        bottom: 4.85rem;
      }

      @media only screen and (max-width: 768px) {
        width: 26rem;
        height: 14rem;
        left: 2rem;
        bottom: 2.85rem;
      }

      @media only screen and (max-width: 575.98px) {
        display: none;
      }
    }
  }
}
