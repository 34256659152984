.WalkingRecognition {
  background-color: #1D3241;
  background-image: url('../../images/hexa-bg.svg');
  background-position: center;
  background-repeat: repeat-y;
  background-size: 100%;

  @media only screen and (max-width: 767.98px) {
    background-size: 300%;
  }

  main {
    background: transparent;
    margin-top: 3rem;

    h1, h3 {
      color: #ffffff;
      text-align: center;

      span {
        color: #FFCE48;
      }
    }

    .TextList {
      color: #ffffff;
      padding: 0;
      margin: 0;

      li {
        list-style: none;
      }
    }

    .List {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      color: #ffffff;

      @media only screen and (max-width: 767.98px) {
        display: block;
        padding: 0;
      }

      li {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 32%;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: #FFCE48;
        list-style: none;
        margin-bottom: 30px;
        background-color: #132735;
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.141176);
        margin: 0 auto 3rem;
        height: 16rem;
        text-decoration: none;
        padding: 1.5rem .5rem;
        border-radius: 3px;

        @media only screen and (max-width: 767.98px) {
          width: 100%;
        }

        &>div {
          height: 140px;
          margin-right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          max-width: 90px;
          width: 90px;
          display: block;
          marign: 0 auto;
        }
      }
    }
  }

  .Utilize {
    color: #ffffff;

    h4 {
      color: #FFCE48;

      & + div {
        height: 8rem;
      }
    }

    .Box {
      background-color: #132735;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.141176);
      margin: 0 auto 3rem;
      height: 85%;
      display: block;
      text-decoration: none;
      padding: 1.5rem;
      border-radius: 3px;
    }
  }

  .videoContainer {
    position: relative;

    iframe {
      width: 100%;
      margin: 30px 0 50px;
      height: 30rem;
      position: relative;
      z-index: 50;

      @media only screen and (max-width: 991.98px) {
        height: 20rem;
      }

      @media only screen and (max-width: 767.98px) {
        height: 13rem;
        margin: 0 0 1rem;
      }
    }

    &:before {
      content: '';
      position: absolute;
      background: #3BB1E6;
      width: 50rem;
      height: 28rem;
      z-index: 10;
      top: 20px;
      right: -8px;

      @media only screen and (max-width: 1480px) {
        right: -8px;
      }

      @media only screen and (max-width: 1400px) {
        width: 45rem;
        height: 25rem;
        right: -8px;
        top: 3.25rem;
      }

      @media only screen and (max-width: 1199.98px) {
        width: 37rem;
        height: 21rem;
        right: -0.5rem;
        top: 5.25rem;
      }

      @media only screen and (max-width: 992px) {
        width: 28rem;
        height: 15.5rem;
        right: -0.5rem;
        top: 3.25rem;
      }

      @media only screen and (max-width: 768px) {
        width: 26rem;
        height: 15.5rem;
        right: 2.25rem;
        top: 1rem;
      }

      @media only screen and (max-width: 575.98px) {
        display: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 50rem;
      height: 28rem;
      z-index: 10;
      bottom: 45px;
      left: -10px;
      padding: 2px;
      background: #3BB1E6;
      -webkit-mask:
         linear-gradient(#fff 0 0) content-box,
         linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude;

      @media only screen and (max-width: 1480px) {
        left: -10px;
      }

      @media only screen and (max-width: 1400px) {
        width: 45rem;
        height: 25rem;
        left: -8px;
        bottom: 5rem;
      }

      @media only screen and (max-width: 1199.98px) {
        width: 38rem;
        height: 21rem;
        left: -0.75rem;
        bottom: 6.85rem;
      }

      @media only screen and (max-width: 992px) {
        width: 28rem;
        height: 15rem;
        left: -0.75rem;
        bottom: 4.85rem;
      }

      @media only screen and (max-width: 768px) {
        width: 26rem;
        height: 14rem;
        left: 2rem;
        bottom: 2.85rem;
      }

      @media only screen and (max-width: 575.98px) {
        display: none;
      }
    }
  }

  .WalkingImageItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8rem;

    @media only screen and (max-width: 767.98px) {
      flex-direction: column-reverse;
      margin-bottom: 4rem;
    }

    &:last-child {
      @media only screen and (max-width: 767.98px) {
        margin-bottom: 6rem;
      }
    }

    .WalkingImageItemImg {
      position: relative;
      width: 70%;

      @media only screen and (max-width: 1400px) {
        width: 65%;
      }

      @media only screen and (max-width: 767.98px) {
        width: 100%;
      }

      img {
        display: block;
        width: 100%;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        bottom: -8px;
        right: -8px;
        padding: 4px;
        background: #3BB1E6;
        -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;

        @media only screen and (max-width: 1480px) {
          right: -10px;
        }

        @media only screen and (max-width: 1400px) {
          width: 93%;
          right: -8px;
        }

        @media only screen and (max-width: 1199.98px) {
          right: -8px;
        }
      }
    }

    .WalkingImageItemText {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35%;
      margin-left: -5%;
      position: relative;
      z-index: 10;

      @media only screen and (max-width: 1400px) {
        width: 40%;
      }

      @media only screen and (max-width: 767.98px) {
        width: 100%;
        margin: 0 auto -5%;
      }

      @media only screen and (max-width: 575.98px) {
        margin: 0 auto 5%;
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        padding: 0 5.25rem 0 3.75rem;
        width: 330px;
        height: 250px;
        background: transparent url('../../images/hexa_bg.svg') no-repeat center;
        background-size: 100%;

        @media only screen and (max-width: 767.98px) {
          height: 260px;
        }
      }

      p {
        margin: 0;
        font-size: 15px;

        @media only screen and (max-width: 1199.98px) {
          font-size: 13px;
        }

        @media only screen and (max-width: 767.98px) {
          font-size: 16px;
        }
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      @media only screen and (max-width: 767.98px) {
        flex-direction: column-reverse;
      }

      .WalkingImageItemText {
        margin-left: 0;
        margin-right: -5%;

        @media only screen and (max-width: 767.98px) {
          margin-right: unset;
          margin-bottom: -5%;
        }

        @media only screen and (max-width: 575.98px) {
          margin: 0 auto 5%;
        }

        div {
          padding: 0 3.75rem 0 5.25rem;
          background: transparent url('../../images/hexa_bg_swap.svg') no-repeat center;
          background-size: 100%;
        }
      }

      .WalkingImageItemText {
        &:after {
          bottom: unset;
          right: unset;
          top: -8px;
          left: -8px;
        }
      }
    }
  }

  .Faq {
    .AccordionItem {
      border: 1px solid #20b9f5;
    }

    button {
      background-color: #132735;
      color: #ffffff;

      &:after {
        filter: brightness(0) saturate(100%) invert(100%) sepia(3%)
          saturate(7436%) hue-rotate(183deg) brightness(119%) contrast(101%);
      }
    }

    h2 {
      & + div {
        div {
          background-color: #1D3241;
          color: #ffffff;
        }
      }
    }
  }
}
