.CookieConsent {
  a {
    color: #3BB1E6;

    &:hover {
      color: #3BB1E6;
    }
  }
}

.Button {
  background-color: #3BB1E6 !important;
  color: #ffffff !important;

  &:hover {
    color: #000000 !important;
  }
}
