.BackToLink {
  text-decoration: none;
  transition: all .15s cubic-bezier(.4,0,.2,1);
  color: #20b9f5;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 700;
  line-height: calc(.875rem + 10px);
  min-width: auto;
  padding: .5rem .25rem;
  position: relative;
  z-index: 2;

  &:after {
    width: 0;
  }

  &:hover {
    color: #ffffff;
    text-decoration: none;

    &:after {
      transition: all .25s cubic-bezier(.4,0,.2,1);
      background-color: rgba(32,185,245,.25);
      bottom: .5rem;
      content: "";
      height: 22px;
      left: 18px;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }

  &:active {
    color: grey;

    &:after {
      width: 7%;
    }
  }

  svg {
    display: inline-block;
    margin-right: 9px;
    margin-top: 3px;
    transition: all .25s ease;
  }

  &:hover {
    svg {
      transform: translate(-8px);
      transition: all .25s ease;
    }
  }
}
