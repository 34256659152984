.section-process {
  --gradient-color-1: #43BC96;
  --gradient-color-2: #6ec3f4;
  --gradient-color-3: #ffce48;
  --gradient-color-4: #c9c9c9;

  @media only screen and (max-width: 992px) {
    text-align: center;
    width: 100%;
    margin-bottom: 2rem;
  }

  .process-steps-container {
    .process-step-container {
      .process-step-title-container {
        margin-top: -16px;
        position: relative;

        .process-step-title,
        .process-step-title-overlay {
          will-change: transform;
          font-size: 5rem;
          font-weight: 900;
          margin: 0;
          line-height: 1.2em;
          letter-spacing: -4px;

          @media only screen and (max-width: 575.98px) {
            font-size: 3.75rem;
          }
        }

        .process-step-title-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: auto;
          padding-right: 30px;
          height: 100%;
          opacity: 1;
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;

          @media only screen and (max-width: 992px) {
            width: 100%;
            text-align: center;
            padding: 0;
          }
        }
      }

      &.process-step-1 {
        margin-bottom: 1rem;

        .process-step-title-overlay {
          background-image: linear-gradient(
            90deg,
            var(--gradient-color-1),
            var(--gradient-color-2)
          );
          animation: animated-gradient-title-1 8s infinite;
        }
      }

      &.process-step-2 {
        .process-step-title-overlay {
          background-image: linear-gradient(
            90deg,
            var(--gradient-color-2),
            var(--gradient-color-3)
          );
          animation: animated-gradient-title-2 8s infinite;
        }
      }
    }
  }

  h1 {
    text-transform: none;
    font-smooth: always;
  }

  @keyframes animated-gradient-title-1 {
    0%, 50%, 100% {
      opacity: 1;
    }
    25%, 75% {
      opacity: 0;
    }
  }

  @keyframes animated-gradient-title-2 {
    0%, 50%, 100% {
      opacity: 0;
    }
    25%, 75% {
      opacity: 1;
    }
  }
}
