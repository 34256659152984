@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/bootstrap-utilities';

.Badge {
  @extend .badge;
  @extend .me-1;

  background-color: rgba(172, 212, 231, 0.3);
  border-radius: 9px;
  color: #3BB1E6;
  font-size: 13px;
  text-decoration: none;
  transition: all .2s ease;

  &:hover {
    color: #3BB1E6;
    background-color: rgba(172, 212, 231, 0.6);
    text-decoration: none;
    transition: all .2s ease;
  }
}
