@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/bootstrap-utilities';

.PostCard {
  @extend .card;
  @extend .h-100;

  border: 0;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.141176);
  color: #1D3241;
  background-color: #132735;

  h3 {
    text-align: left !important;
  }
}

.CardTitle {
  @extend .card-title;

  font-size: 26px;
  font-weight: 900;
  min-height: 100px;

  a {
    color: inherit;
    text-decoration: none;
    color: #FFCE48;
    transition: all .15s cubic-bezier(.4,0,.2,1);
    display: -webkit-box;
    margin: 0;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    &:hover {
      color: #3BB1E6;
      transition: all .15s cubic-bezier(.4,0,.2,1);
    }
  }
}

.CardText {
  @extend .card-text;

  cursor: pointer;
  font-size: 16px;
  font-weight: 400;

  p {
    display: -webkit-box;
    margin: 0;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    color: #ffffff;
  }
}

.CardFooter {
  @extend .card-footer;
  @extend .border-0;
  @extend .my-2;

  background-color: #132735;
  color: #3BB1E6;

  div {
    @extend .d-flex;
    @extend .flex-row;
    @extend .pt-3;

    border-top: 1px solid #9B9B9B55 !important;
  }
}
