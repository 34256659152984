.Pagination {
  .page-item {
    border: 0;
    margin: 0 0.25rem;
    font-weight: 700;

    &.active {
      a {
        background: linear-gradient(107.1deg, #3BB1E6 8.33%, #43DDFB 93.63%);
        border-radius: 4px;
        font-family: 'Karla';
        font-style: normal;
        font-weight: 800;
        font-size: 17px;
        text-align: center;
        color: #FFFFFF;
        padding: 15px 25px;
      }
    }

    a {
      border: 0;
      border-radius: 4px;
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .141176);
      color: #3BB1E6;
      transition: all .25s ease;

      &:hover {
        background: linear-gradient(107.1deg, #3BB1E6 8.33%, #43DDFB 93.63%);
        color: #ffffff;
        transition: all .25s ease;
      }
    }
  }
}
