.BaseText {
  font-size: 60px;
  font-weight: 900;
  left: 50%;
  opacity: 0.4;
  position: absolute;
  top: 50%;
  transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  pointer-events: none;
  will-change: transform;
}

.Cybersecurity {
  @extend .BaseText;

  transform: translate(-400px, -310px) scale(0.9);
  background: linear-gradient(-60deg,#54C19F,#ffce48);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media only screen and (max-width: 991px) {
    transform: translate(-84px, -580px) scale(0.9);
  }

  @media only screen and (max-width: 575.98px) {
    opacity: 1;
    transform: translate(-100px, -580px) scale(0.7);
  }

  &.CybersecurityHover {
    opacity: 1;
    transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transform: translate(-400px, -310px);

    @media only screen and (max-width: 991px) {
      opacity: 1;
      transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transform: translate(-84px, -580px);
    }

    @media only screen and (max-width: 575.98px) {
      transform: translate(-100px, -580px) scale(0.7);
    }
  }
}

.Neurology {
  @extend .BaseText;

  background: linear-gradient(90deg, #6ec3f4, #FFCE48);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  transform: translate(260px, -170px) scale(0.9);

  @media only screen and (max-width: 991px) {
    transform: translate(-390px, -178px) scale(0.9);
    width: 470px;
  }

  @media only screen and (max-width: 575.98px) {
    opacity: 1;
    transform: translate(-230px, -178px) scale(0.7);
  }

  &.NeurologyHover {
    opacity: 1;
    transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transform: translate(260px, -170px);

    @media only screen and (max-width: 991px) {
      opacity: 1;
      transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transform: translate(-230px, -178px);
    }

    @media only screen and (max-width: 575.98px) {
      transform: translate(-230px, -178px) scale(0.8);
    }
  }
}

.Biometrics {
  @extend .BaseText;

  background: linear-gradient(90deg, #54C19F, #5DC9F0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  transform: translate(-240px, 204px) scale(0.9);

  @media only screen and (max-width: 991px) {
    transform: translate(-30px, 100px) scale(0.9);
  }

  @media only screen and (max-width: 575.98px) {
    opacity: 1;
    transform: translate(-40px, 100px) scale(0.7);
  }

  &.BiometricsHover {
    opacity: 1;
    transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transform: translate(-240px, 204px);

    @media only screen and (max-width: 991px) {
      opacity: 1;
      transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transform: translate(-30px, 100px);
    }

    @media only screen and (max-width: 575.98px) {
      transform: translate(-40px, 100px) scale(0.7);
    }
  }
}
