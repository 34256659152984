#menu__toggle {
  display: none;
}

@media only screen and (max-width: 991.98px) {
  #menu__toggle {
    opacity: 0;

    &:checked {
      & ~ .menu__btn {
        & > span {
          transform: rotate(45deg);

          &:before {
            top: 0;
            transform: rotate(0);
          }

          &:after {
            top: 0;
            transform: rotate(90deg);
          }
        }
      }

      & ~ .menu__box {
        visibility: visible;
        left: 0;
      }
    }
  }

  .menu__btn {
    display: flex;
    align-items: center;
    position: absolute;
    top: 12px;
    right: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 1;

    & > span,
    & > span::before,
    & > span::after {
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #fff;
      transition-duration: .25s;
    }

    & > span::before {
      content: '';
      top: -8px;
    }

    & > span::after {
      content: '';
      top: 8px;
    }
  }
}

.navbar-collapse {
  @media only screen and (max-width: 991.98px) {
    overflow-y: scroll !important;
    max-height: 100vh !important;
    padding-bottom: 6rem !important;
    -overflow-scrolling: touch !important;
    -webkit-overflow-scrolling: touch !important;
  }
}

.navbar-collapse::-webkit-scrollbar {
  display: none;
}
