@import 'bootstrap';

.ButtonPrimary {
  @extend .btn;
  padding: 16px 30px;
  background-color: #3bb1e6;
  background-image: linear-gradient(45deg,#3bb1e6,#43ddfb,#3bb1e6,#1d3241);
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 300%;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .141176);
  border-radius: 5px !important;
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  transition: all .3s ease;

  &:hover {
    color: #FFFFFF;
    background-color: #0aa7e5;
    background-position: 50%;
    border-color: #0a9ed8;
    transform: scale(1.02);
    box-shadow: 0 4px 8px 0 rgba(0, 166, 230, .28);
  }

  &:active {
    box-shadow: none;
    color: rgba(255, 255, 255, .75);
    transition-duration: .1s;
    transform: scale(0.99) translateY(1px);
  }
}

.ButtonPrimaryLg {
  @extend .ButtonPrimary;

  background-color: #3BB1E6 !important;
  color: #FFFFFF;
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin: 0 auto;
  min-width: 256px !important;
}

.ButtonDark {
  @extend .btn;
  width: 238px !important;
  background: #1D3241;
  box-shadow: 0px 8px 8px rgba(67, 221, 251, 0.1);
  border-radius: 999px;
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  padding: 16px 0;
  transition: all .3s ease;

  &:hover {
    color: #FFFFFF;
    transform: scale(1.02);
    box-shadow: 0 4px 8px 0 rgba(0, 166, 230, .28);
    transition: all .3s ease;
  }

  &:active {
    box-shadow: none;
    color: rgba(255, 255, 255, .75);
    transition-duration: .1s;
    transform: scale(0.99) translateY(1px);
  }
}
