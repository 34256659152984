.Cybersecurity {
  padding-top: 4rem;
  background-image: url('../../images/contact-form-ci-bg.svg');
  background-position: top center;
  background-repeat: no-repeat;

  @media only screen and (max-width: 767.98px) {
    padding-top: 1rem;
  }

  .cyberIntro {
    p {
      text-align: center;
      color: #ffffff;
      font-size: 21px;

      a {
        margin-right: 6px;
      }
    }

    a {
      display: inline-block;
      cursor: pointer;
    }
  }

  .VideoContainer {
    border-radius: 100%;
    overflow: hidden;
    width: 20rem;
    height: 20rem;
    margin: 0 auto !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 991.98px) {
      margin: 1.5rem auto 0 !important;
      height: auto !important;
      width: 100%;
    }

    video {
      display: block;
      width: 90%;
      margin: 0 auto;
      clip-path: circle(49% at 50% 50%);
    }
  }
}
