.OurTeam {
  padding-top: 2rem;
  background-image: url('../../images/contact-form-ci-bg.svg');
  background-position: top center;
  background-repeat: no-repeat;

  p {
    a {
      text-decoration: none;
      color: #3BB1E6 !important;
    }
  }

  .OurTeamContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 65px;
  }

  .OurTeamImg {
    overflow: hidden;
    width: calc(50% - 13px);
    object-fit: cover;
    margin-bottom: 26px;

    &:nth-child(odd) {
      margin-right: 13px;
    }

    &:nth-child(even) {
      margin-left: 13px;
    }

    &:nth-child(1) {
      border-radius: 0px 0px 50px 0px;
    }

    &:nth-child(2) {
      border-radius: 0px 0px 0px 50px;
      margin: 30px 0 50px;

      @media only screen and (max-width: 767.98px) {
        margin: 0 0 25px;
      }
    }

    &:nth-child(3) {
      border-radius: 0px 50px 0px 0px;
      margin: 30px 0 50px;

      @media only screen and (max-width: 767.98px) {
        margin: 0 0 25px;
      }
    }

    &:nth-child(4) {
      border-radius: 50px 0px 0px 0px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media only screen and (max-width: 767.98px) {
      width: 100%;
      margin-bottom: 25px;
    }
  }
}

.TeamGoals {
  margin: 8rem 0 3rem;
  display: flex;
  justify-content: center;
  transform: scale(0.65);

  @media only screen and (max-width: 767.98px) {
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 575.98px) {
    margin: 2.5rem auto;
    transform: scale(1);
  }

  .HexaOuter {
    position: relative;

    @media only screen and (max-width: 767.98px) {
      width: 50%;
    }

    @media only screen and (max-width: 575.98px) {
      position: static;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 7px;
      height: 60px;
      background: linear-gradient(180deg, #43BC96 0%, rgba(255, 255, 255, 0) 100%);
      z-index: 20;

      @media only screen and (max-width: 575.98px) {
        display: none;
      }
    }

    &:nth-child(odd) {
      margin-top: -153px;

      @media only screen and (max-width: 575.98px) {
        margin-top: 0;
      }
    }

    &:nth-child(1) {
      &:after {
        transform: rotate(90deg);
        top: 102px;
        left: -76px;
      }

      @media only screen and (max-width: 767.98px) {
        top: 0px;
        left: -35px;

        &:after {
          transform: rotate(215deg);
          top: -72px;
          left: 237px;
        }
      }

      @media only screen and (max-width: 575.98px) {
        left: 8px;
        top: 10px;

        &:after {
          transform: rotate(-150deg);
          left: 160px;
          top: -39px;
        }
      }
    }

    &:nth-child(2) {
      &:after {
        transform: rotate(-35deg);
        bottom: -74px;
        right: 23px;
      }

      @media only screen and (max-width: 767.98px) {
        top: 0px;

        &:after {
          bottom: -373px;
          right: -8px;
        }
      }

      @media only screen and (max-width: 575.98px) {
        top: -59px;
        left: -8px;
        right: unset;

        &:after {
          bottom: -203px;
          right: -6px;
        }
      }
    }

    &:nth-child(3) {
      &:after {
        transform: rotate(140deg);
        top: -72px;
        left: 23px;
      }

      @media only screen and (max-width: 767.98px) {
        top: 41px;
        left: -35px;

        &:after {
          transform: rotate(35deg);
          top: 280px;
          left: 23px;
        }
      }

      @media only screen and (max-width: 575.98px) {
        left: 8px;
        top: -20px;

        &:after {
          left: 31px;
          top: 174px;
        }
      }
    }

    &:nth-child(4) {
      &:after {
        transform: rotate(220deg);
        top: -72px;
        right: 23px;
      }

      @media only screen and (max-width: 767.98px) {
        top: 35px;

        &:after {
          display: none;
        }
      }

      @media only screen and (max-width: 575.98px) {
        top: -89px;
        left: -8px;
        right: unset;
      }
    }
  }

  .Hexa {
    text-align: center;
    background: #43BC96;
    display: flex;
    justify-content: center;
    clip-path: polygon(0 50%,25% 6.7%,75% 6.7%,100% 50%,75% 93.3%,25% 93.3%);
    height: 265px;
    width: 269px;
    transform: scale(1.35);

    @media only screen and (max-width: 575.98px) {
      display: block;
      text-align: center;
      transform: scale(1);
      clip-path: unset;
      width: auto;
      height: auto;
      background: transparent;
    }

    .HexaInner {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: calc(100% - 8px);
      width: calc(100% - 8px);
      justify-content: center;
      clip-path: polygon(0 50%,25% 6.7%,75% 6.7%,100% 50%,75% 93.3%,25% 93.3%);
      background: #335C6E;
      margin-top: 4px;

      @media only screen and (max-width: 575.98px) {
        clip-path: unset;
        display: block;
        width: auto;
        height: auto;
        background: transparent;
        margin-bottom: 1.85rem;
      }
    }

    h5 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0.75rem;
      color: #FFCE48;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        font-size: 18px;
        color: #ffffff;

        @media only screen and (max-width: 575.98px) {
          margin-bottom: .25rem;
        }
      }
    }
  }
}

.Founder {
  background-color: #132735;
  border-top: solid 4px #2eb1e7;
  border-bottom: solid 4px #2eb1e7;

  @media only screen and (max-width: 991.98px) {
    margin-bottom: 2rem;
  }

  .FounderData {
    padding: 20px 10px;
    min-height: 135px;
  }

  .FounderName {
    color: #FFCE48;
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .FounderPosition {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #3BB1E6;
  }

  .FounderContact {
    display: flex;
    justify-content: flex-end;

    a {
      margin-left: 5px;
    }
  }

  img {
    display: block;
    width: 100%;
  }
}

.Featured {
  margin: 40px 0px;
  padding: 40px 0px;
  border-top: solid 1px #cacaca;
  border-bottom: solid 1px #cacaca;

  a {
    display: inline-block;
    position: relative;
    width: 185px;
    height: 75px;

    img {
      &:first-child {
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        z-index: 2;
      }

      &:last-child {
        position: absolute;
        top: 0px;
        left: 0px;
        display: none;
        z-index: 1;
      }
    }

    &:hover {
      img:frist-child {
        display: none;
        z-index: 1;
      }

      img:last-child {
        display: block;
        z-index: 2;
      }
    }
  }
}

.Career {
  background-color: #2a4960;
  padding: 70px 0 110px;

  .CareerBox {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 991.98px) {
      flex-direction: column-reverse;
    }

    .CareerBoxInner {
      width: 440px;
      background: #f6f6f6;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
      align-self: flex-start;
      border-radius: 4px;

      @media only screen and (max-width: 991.98px) {
        width: 100%;
      }
    }

    header {
      background: url('../../images/cursors-bg.jpg');
      color: #ffffff;
      font-size: 17px;
      padding: 25px 20px;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -15px;
        left: 35px;
        width: 25px;
        height: 15px;
        background: transparent url('../../images/intro-box-arrow.png') no-repeat center;
      }

      h5 {
        margin: 0;
      }
    }

    article {
      padding: 20px;

      ul {
        margin-top: 1rem;

        li {
          margin-bottom: 1rem;
        }
      }
    }

    footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 25px;
    }
  }

  .CareerList {
    width: calc(100% - 500px);

    @media only screen and (max-width: 991.98px) {
      width: 100%;
      margin-bottom: 3rem;
    }
  }

  [class=accordion] {
    [class=accordion-item] {
      border: none;
      background-color: transparent;
    }

    [class~=accordion-button] {
      background-color: transparent !important;
      color: #1D3241;
      box-shadow: none;
      display: flex;
      justify-content: space-between;
      padding: 14px 0;

      @media only screen and (max-width: 575.98px) {
        display: block;
      }

      h4 {
        color: #FFCE48;
        font-weight: 700;
        font-size: 26px;
        line-height: 36px;
        text-transform: uppercase;
        margin-bottom: 0;

        @media only screen and (max-width: 991px) {
          width: 65%;
        }

        @media only screen and (max-width: 575.98px) {
          width: 100%;
        }
      }

      span {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #3BB1E6;

        @media only screen and (max-width: 575.98px) {
          width: 40%;
        }
      }

      &:after {
        display: none;
      }
    }

    [class=accordion-body] {
      background: #f6f6f6;
      padding: 20px 35px;
      border-radius: 4px;
    }
  }
}
