.resources-slider {
  .container {
    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  h2 {
    color: #ffffff;
    font-family: 'Karla', sans-serif;
    font-size: 54px;
    font-weight: 800;
    text-align: center;
    line-height: 80px;
    background: linear-gradient(90deg, #3BB1E6 0%, #43DDFB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media only screen and (max-width: 767.98px) {
      font-size: 42px;
    }
  }

  .slick-slide {
    padding: 0 30px;

    img {
      width: 100%;
    }
  }

  .slick-slider {
    padding: 24px 0 84px 0;

    @media only screen and (max-width: 991.98px) {
      padding: 20px 0;
    }

    .slick-dots {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 36px;
      bottom: 1.5rem;

      li {
        width: 8px;
        height: 8px;
        border-radius: 100px;
        margin: 0 8px;
        transition: width .2s ease;
        background: #3BB1E6;

        &.slick-active {
          width: 45px;
        }

        button {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
