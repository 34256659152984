.Roadmap {
  h3 {
    font-weight: 800;
    font-size: 48px;
    line-height: 120%;
    color: #FFCE48;
    margin-bottom: 1rem;
  }

  p {
    font-size: 18px;
    font-weight: 500;
  }

  li {
    margin-bottom: 12px;
    line-height: 140%;
    list-style: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      background-image: url('../../images/list-item.svg');
      background-size: 100%;
      width: 12px;
      height: 16px;
      display: block;
      position: absolute;
      left: -22px;
      top: 4px;
    }
  }

  img {
    display: block;
    max-width: 100%;
    width: 20rem;
    margin: 0 auto;
  }
}

.ContactForm {
  background-color: #1D3241 !important;
  background-image: url('./contact-form-ci-bg.svg');
  background-position: top center;
  background-repeat: no-repeat;
  color: #ffffff;
  min-height: 800px;
  position: relative;
  padding-top: 3rem;

  @media only screen and (max-width: 575.98px) {
    padding-top: 5rem;
  }

  h1 {
    text-align: center;
  }

  h2 {
    text-transform: unset;
    font-family: 'Karla';
    font-size: 54px;
    font-weight: 800;
    text-align: center;
    line-height: 80px;
    background: linear-gradient(90deg, #3BB1E6 0%, #43DDFB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media only screen and (max-width: 575.98px) {
      font-size: 32px;
      line-height: 34px;
    }
  }

  .FormBox {
    margin-left: auto;
    margin-right: auto;
    width: calc(min(567px, 100%));

    .Form {
      background-color: transparent !important;

      a {
        text-decoration: none;
        transition: all .15s ease;
        color: #3BB1E6;
        display: inline-flex;
        font-size: inherit;
        font-weight: 700;
        line-height: calc(.875rem + 10px);
        min-width: auto;
        padding: 0;
        position: relative;
        z-index: 2;

        &:after {
          width: 0;
          transition: all .25s ease;
        }

        &:hover {
          text-decoration: none;
          color: #3BB1E6;
          transition: all .25s ease;

          &:after {
            transition: all .25s ease;
            background-color: rgba(32,185,245,.35);
            bottom: .25rem;
            content: "";
            height: 21px;
            left: 0;
            bottom: 0;
            position: absolute;
            width: 100%;
            z-index: -1;
          }
        }

        &:active {
          color: grey;

          &:after {
            width: 7%;
          }
        }
      }

      label {
        margin-bottom: 0.3rem;

        @media only screen and (max-width: 575.98px) {
          font-size: 14px;
        }
      }

      input,
      textarea {
        background-color: #2c2c2e;
        color: #ffffff;
        border-radius: .25rem;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        border: 1px solid #e6e6e6;
        display: block;
        font-size: 1rem;
        font-weight: 400;
        height: calc(1.5em + 1.6rem + 2px);
        line-height: calc(1rem + 10px);
        line-height: 1.5;
        padding: .8rem 1rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

        &:focus,
        &:hover {
          outline: none;
          border-color: #3BB1E6;
          box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 0 .2rem rgba(27,252,190,.25);
        }
      }

      input[type=checkbox] {
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 1rem;
        height: 1rem;
        padding: 10px;
        margin-right: .25rem;
        border: 0.15rem solid currentColor;
        border-radius: 0.25em;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;

        &:before {
          content: "";
          width: 0.65em;
          height: 0.65em;
          clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          transform: scale(0);
          transform-origin: bottom left;
          transition: 120ms transform ease-in-out;
          box-shadow: inset 1em 1em #ffffff;
          background-color: CanvasText;
        }

        &:checked::before {
          transform: scale(1);
        }
      }

      .SendButton {
        display: block;
        margin: 0 auto;
        text-transform: uppercase;
        border-radius: 999px;
      }

      small {
        margin-left: 0.5rem;
        color: #bcbcbc !important;
      }
    }

    .ButtonPrimary {
      padding: 16px 30px;
      background-color: #3bb1e6;
      background-image: linear-gradient(45deg,#3bb1e6,#43ddfb,#3bb1e6,#1d3241);
      background-position: 0;
      background-repeat: no-repeat;
      background-size: 300%;
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .141176);
      border-radius: 5px !important;
      font-family: 'Karla';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #FFFFFF;
      transition: all .3s ease;
      margin: 0 auto;
      display: block;

      &:hover {
        color: #FFFFFF;
        background-color: #0aa7e5;
        background-position: 50%;
        border-color: #0a9ed8;
        transform: scale(1.02);
        box-shadow: 0 4px 8px 0 rgba(0, 166, 230, .28);
      }


      &:active {
        box-shadow: none;
        color: rgba(255, 255, 255, .75);
        transition-duration: .1s;
        transform: scale(0.99) translateY(1px);
      }
    }
  }
}
