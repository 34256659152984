.PartnerSlider {
  .slick-dots {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
    bottom: -2.5rem;

    li {
      width: 8px;
      height: 8px;
      border-radius: 100px;
      margin: 0 8px;
      transition: width .2s ease;
      background: #3BB1E6;

      &.slick-active {
        width: 45px;
      }

      button {
        &:before {
          display: none;
        }
      }
    }
  }
}
