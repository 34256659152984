@import './HexagonHover.module.scss';

.hexagonsMain {
  //background: radial-gradient(circle farthest-side, #1F526A, #1D3241);
  background: #092756;
   background: -moz-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%),-moz-linear-gradient(top, rgba(57,173,219,.25) 0%, rgba(42,60,87,.4) 100%), -moz-linear-gradient(-45deg, #1D3241 0%, #092756 100%);
   background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -webkit-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -webkit-linear-gradient(-45deg, #1D3241 0%,#092756 100%);
   background: -o-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -o-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -o-linear-gradient(-45deg, #1D3241 0%,#092756 100%);
   background: -ms-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -ms-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -ms-linear-gradient(-45deg, #1D3241 0%,#092756 100%);
   background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), linear-gradient(to bottom, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), linear-gradient(135deg, #1D3241 0%,#092756 100%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.ClipPath {
  clip-path: polygon(0% 50%, 25% 6.7%, 75% 6.7%, 100% 50%, 75% 93.3%, 25% 93.3%);
  height: 216px;
  width: 216px;
}

.Hexagon {
  text-decoration: none;

  .hexa-border {
    @extend .ClipPath;

    display: flex;
    justify-content: center;
    align-items: center;

    .hexa-bg {
      @extend .ClipPath;

      height: calc(100% - 8px);
      width: calc(100% - 8px);

      display: flex;
      justify-content: center;
      align-items: center;

      .hexa-shadow {
        height: calc(100% - 8px);
        width: calc(100% - 8px);

        display: flex;
        justify-content: center;
        align-items: center;

        .hexa-selector {
          @extend .ClipPath;
          //transition: all .1s ease-in;
          transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

          background: transparent;
          height: inherit;
          width: inherit;

          padding: 1.5rem 0;

          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          text-align: center;

          h3 {
            font-size: 20px;
            font-weight: 700;
            margin: 0;
            padding: 0;
            text-align: center;
            max-width: 144px;
          }

          img {
            margin: 0;
            padding: 0;
            height: 19px;
          }
        }
      }
    }
  }

  &:hover {
    @extend .HexagonHover;
  }
}
