.UseCases {
  background-image: url('../../images/contact-form-ci-bg.svg');
  background-position: top center;
  background-repeat: no-repeat;

  p {
    a {
      cursor: pointer;
      color: #3BB1E6 !important;
    }
  }

  .usecaseIntro {
    margin-top: 1rem;
    margin-bottom: 5rem;
    text-align: center;

    [class=col-md-4] {
      height: 12rem;
    }

    .Image {
      width: 5rem;
      height: 5.5rem;
      margin: 0 auto 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      display: block;
      width: 100%;
      transition: all .3s ease;
    }

    a {
      text-decoration: none;
      background-color: #132735;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.141176);
      display: block;
      width: 60%;
      margin: 0 auto;
      padding: 1.5rem 0 1rem;
      border-radius: 3px;
      position: relative;
      overflow: hidden;
      transition: all 0.3s ease-in-out;

      &:before {
        position: absolute;
        top: 0;
        left: -75%;
        z-index: 2;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
        background: linear-gradient(to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 100%);
        transform: skewX(-25deg);
      }

      &:hover {
        transform: scale(1.1);
        transition: all 0.3s ease-in-out;

        &:before {
          animation: shine 0.75s;
        }
      }
    }

    h2 {
      text-align: center;
      color: #FFCE48;
      margin-bottom: 3rem;
    }

    h5 {
      text-align: center;
      color: #ffffff;
      transition: all .3s ease;
    }
  }

  .usecaseCard {
    width: 50%;
    margin: 0 auto;

    @media only screen and (max-width: 991.98px) {
      width: 100%;
    }
  }

  [class=col-lg-6],
  .usecaseCard {
    margin-bottom: 5rem;

    h5 {
      font-weight: 500;
      font-size: 30px;
      line-height: 35px;
      text-transform: uppercase;
      color: #f6f6f6;
      margin-bottom: 6px;

      @media only screen and (max-width: 575.98px) {
        font-size: 22px;
      }
    }

    img {
      margin-bottom: 20px;
      max-width: 100%;
      min-height: 4rem;

      &:nth-child(2) {
        display: none;
      }
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 140%;
      color: #ffffff;
      min-height: 12.5rem;
    }

    span {
      width: 100%;
      display: flex;
      justify-content: space-between;

      a {
        &:only-child {
          margin-left: auto;
        }
      }
    }

    a {
      text-decoration: none;
      transition: all .15s cubic-bezier(.4,0,.2,1);
      color: #20b9f5;
      display: inline-flex;
      font-size: 1rem;
      font-weight: 700;
      line-height: calc(.875rem + 10px);
      min-width: auto;
      padding: .5rem .25rem;
      position: relative;
      z-index: 2;
    }

    div {
      height: 100%;
      background-color: #132735;
      border-radius: 5px;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.141176);
      padding: 30px 50px 15px 30px;
      cursor: pointer;
      transition: all .3s ease;
      position: relative;
      overflow: hidden;
      transition: all 0.3s ease-in-out;

      &:before {
        position: absolute;
        top: 0;
        left: -75%;
        z-index: 2;
        display: block;
        content: "";
        width: 50%;
        height: 100%;
        background: linear-gradient(to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 100%);
        transform: skewX(-25deg);
      }

      &:hover {
        transform: scale(1.035);
        transition: all 0.3s ease-in-out;

        &:before {
          animation: shine 0.75s;
        }
      }
    }
  }
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}
