// Fonts
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap');

// Vendors
@import 'custom-bootstrap';

@import 'font-awesome';

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

// Global
@import './defaults';
