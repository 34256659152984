.Hiring {
  display: flex;
  background-image:
    repeating-linear-gradient(0deg, #43BC96, #43BC96 4px, transparent 4px, transparent 30px, #43BC96 30px),
    repeating-linear-gradient(90deg, #43BC96, #43BC96 4px, transparent 4px, transparent 30px, #43BC96 30px),
    repeating-linear-gradient(180deg, #43BC96, #43BC96 4px, transparent 4px, transparent 30px, #43BC96 30px),
    repeating-linear-gradient(270deg, #43BC96, #43BC96 4px, transparent 4px, transparent 30px, #43BC96 30px);
  background-size: 3px 100%, 100% 3px, 3px 100% , 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  padding: 42px 85px;

  @media only screen and (max-width: 991.98px) {
    display: block;
  }

  @media only screen and (max-width: 575.98px) {
    width: 90%;
    margin: 0 auto;
    padding: 15px;
  }

  .HiringInner {
    position: relative;

    img {
      position: absolute;
      right: 0;
      top: -100px;

      @media only screen and (max-width: 991.98px) {
        left: 10px;
        width: 5rem;
      }

      @media only screen and (max-width: 575.98px) {
        display: none;
      }
    }

    &:first-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      z-index: 5;

      @media only screen and (max-width: 991.98px) {
        width: 100%;
        clip-path: none;
        padding: 24px 16px;
        text-align: center;
      }

      a {
        @media only screen and (max-width: 991.98px) {
          margin: 0 auto;
        }
      }
    }

    &:last-child {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 50%;
      color: #ffffff;

      @media only screen and (max-width: 991.98px) {
        width: 100%;
        clip-path: none;
        position: static;
        margin: 0;
        padding: 24px 16px;
      }

      div {
        display: flex;
        width: 100%;
      }
    }

    h3 {
      font-weight: 800;
      font-size: 48px;
      line-height: 120%;
      color: #FFCE48;
      margin-bottom: 1rem;

      @media only screen and (max-width: 991.98px) {
        padding-left: 0;
      }

      @media only screen and (max-width: 575.98px) {
        font-size: 28px;
      }
    }

    ul {
      padding: 0 0 0 8.5rem;
      margin: 0;
      width: 100%;

      @media only screen and (max-width: 991.98px) {
        padding: 0;
        display: flex;
        justify-content: space-between;
      }

      @media only screen and (max-width: 575.98px) {
        display: block;
        text-align: center;
      }
    }

    li {
      margin-bottom: 45px;
      line-height: 125%;
      list-style: none;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '';
        background-image: url('../../../../images/list-item.svg');
        background-size: 100%;
        width: 12px;
        height: 16px;
        display: block;
        position: absolute;
        left: -22px;
        top: 7px;

        @media only screen and (max-width: 575.98px) {
          display: none;
        }
      }

      @media only screen and (max-width: 1189.98px) {
        margin-bottom: 25px;
      }

      a {
        text-decoration: none;
        color: #ffffff;
        position: relative;
        z-index: 2;

        &:after {
          width: 0;
          transition: all .25s ease;
        }

        &:hover {
          text-decoration: none;
          color: #000000;
          transition: all .25s ease;

          &:after {
            transition: all .25s ease;
            background-color: rgba(255, 206, 72, .85);
            bottom: .25rem;
            content: "";
            height: 24px;
            left: 0;
            bottom: 0;
            position: absolute;
            width: 100%;
            z-index: -1;
          }
        }
      }
    }
  }
}
