@import '~bootstrap/scss/bootstrap';

.SubscriptionForm {
  @extend .mt-5;
  @extend .shadow;

  background-color: #1D3241 !important;
  border-radius: 5px;
  border: 2px solid #FFCE48;

  h1 {
    color: #3BB1E6;
  }
}

.Title {
  color: #3BB1E6;
  display: inline-block;
  font-weight: 900;
  margin-bottom: 1rem !important;
}

.Subtitle {
  margin-bottom: 1.25em !important;
  color: #ffffff !important;
}

.Email {
  display: inline-block !important;
  width: calc(100% - 200px);
  background-clip: padding-box;
  background-color: #2c2c2e;
  color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-right: 1rem !important;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  font-size: 1rem;
  font-weight: 400;
  height: calc(1.5em + 1.6rem + 2px);
  line-height: calc(1rem + 10px);
  line-height: 1.5;
  padding: .8rem 1rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  margin-bottom: 1rem;
  width: auto !important;
  min-width: 70% !important;

  &:focus {
    border-color: #20b9f5;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 0 .2rem rgba(27,252,190,.25);
    color: #2c2c2e;
    outline: 0;
  }

  &:hover {
    border-color: #20b9f5;
  }

  @media only screen and (max-width: 767.98px) {
    display: block;
    width: 100% !important;
    margin: .75rem auto 1rem !important;
  }
}

.SubscribeButton {
  padding: 16px 30px !important;
  background-color: #3bb1e6;
  background-image: linear-gradient(45deg,#3bb1e6,#43ddfb,#3bb1e6,#1d3241);
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 300%;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .141176);
  border-radius: 5px;
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  transition: all .3s ease;

  &:hover {
    color: #FFFFFF;
    background-color: #0aa7e5;
    background-position: 50%;
    border-color: #0a9ed8;
    transform: scale(1.02);
    box-shadow: 0 4px 8px 0 rgba(0, 166, 230, .28);
  }

  &:active {
    box-shadow: none;
    color: rgba(255, 255, 255, .75);
    transition-duration: .1s;
    transform: scale(0.99) translateY(1px);
  }
}
