.Table {
  margin-top: 4rem;

  .TableRow {
    height: 110px;
    padding: 1rem;

    &:first-child {
      height: 80px;
    }
  }

  ul {
    display: table;
    width: 100%;
  }

  li {
    display: table-cell;
  }

  h3 {
    font-weight: 800;
    font-size: 20px;
    line-height: 23px;
    color: #3FC5F0;
    margin: 0;
  }

  h4 {
    font-size: 20px;
    color: #ffffff;
  }

  p {
    font-size: 20px;
    line-height: 23px;
    color: #ffffff;
    margin: 0;
  }

  .TableSide {
    h3, p {
      color: #ffffff;
    }

    &:last-child {
      h3, p {
        color: #c3c3c3;
      }
    }

    h4 {
      height: 60px;
    }

    ul {
      margin: 0;
      padding: 0 0 0 1.25rem;
    }

    li {
      color: #ffffff;
      display: block;
      font-size: 20px;
      font-weight: 300;
      line-height: 28px;
      margin-bottom: 12px;
      position: relative;

      &:before {
        content: '';
        background-image: url('../../../../images/list-arrow.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        width: 10px;
        height: 18px;
        display: block;
        position: absolute;
        left: -22px;
        top: 7px;
      }
    }
  }
}

.OnlyDesktop {
  @media only screen and (max-width: 991.98px) {
    display: none;
  }
}

.OnlyMobile {
  display: none;

  @media only screen and (max-width: 991.98px) {
    display: inline-block;
  }

  .Table {
    margin-top: 1rem;

    & > ul {
      display: flex;
      flex-direction: column;
      width: 100%;

      li {
        width: 100%;

        button {
          font-size: 1rem;
        }
      }
    }

    h3 {
      font-size: 1.5rem;
      margin-bottom: .5rem;
    }

    h4 {
      font-weight: 700;
    }

    p {
      margin-bottom: 1rem;
    }

    li {
      color: #ffffff;
    }
  }

  .mobileRow {
    margin-bottom: 3rem;

    ul {
      display: block;
      padding: 0;
      margin: 0;

      li {
        display: block;
        width: 100%;
        margin-bottom: 6px;
        padding-left: 6px;
        position: relative;

        &:before {
          content: '';
          background-image: url('../../../../images/list-arrow.svg');
          background-size: 100%;
          background-repeat: no-repeat;
          width: 10px;
          height: 18px;
          display: block;
          position: absolute;
          left: -10px;
          top: 4px;
        }
      }
    }
  }

  .Grey {
    color: #c3c3c3;
  }
}