.BiometricEsignatureBanking {
  padding-top: 3rem;
  background-image: url('../../images/contact-form-ci-bg.svg');
  background-position: top center;
  background-repeat: no-repeat;

  @media only screen and (max-width: 767.98px) {
    padding-top: 1rem;
  }

  .Intro {
    .Card {
      background-color: #132735;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.141176);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18rem;
      margin: 0 auto 3rem;
      padding: 1.5rem 1.5rem 2.5rem;
      border-radius: 3px;

      img {
        margin: 0;
      }
    }

    p {
      text-align: center;
      color: #ffffff;
      font-size: 26px;

      strong {
        color: #FFCE48;
      }

      a {
        color: #ffffff;
        font-weight: normal;
      }
    }
  }

  .Center {
    p {
      color: #ffffff;
    }
  }

  .Element {
    h2 {
      font-size: 54px;
      font-weight: 800;
      color: #FFCE48;
    }

    p {
      font-size: 20px;
      color: #ffffff;
    }

    .Icons {
      position: relative;

      img {
        &:nth-child(2) {
          width: 60px;
          position: absolute;
          top: 10px;
          right: 120px;

          @media only screen and (max-width: 1400px) {
            right: 90px;
          }

          @media only screen and (max-width: 1200px) {
            right: 60px;
          }

          @media only screen and (max-width: 992px) {
            right: 250px;
          }

          @media only screen and (max-width: 768px) {
            right: 160px;
          }
        }
      }
    }

    img {
      display: block;
      width: 110px;
      height: 110px;
      margin: 2.5rem auto 2rem;
    }
  }

  .SignoWise {
    @media only screen and (max-width: 767.98px) {
      width: 100%;
    }
  }

  .Results {
    text-align: center;

    .ResultItem {
      background-color: #132735;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.141176);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 18rem;
      height: 16rem;
      padding: 4rem 1.5rem 1.5rem;
      margin: 0 auto;
      border-radius: 3px;

      @media only screen and (max-width: 767.98px) {
        margin: 0 auto 2rem;
      }
    }

    h2,
    h3 {
      font-size: 46px;
      font-weight: 800;
      color: #FFCE48;
      margin: 0;
    }

    p {
      font-size: 20px;
      color: #ffffff;
      height: 3.5rem;
    }

    span {
      font-size: 14px;
      color: #ffffff;
    }
  }
}
