.SearchBar {
  color: #ffffff;
  padding-left: 12px;
  padding-right: 12px;
  z-index: 100;

  [class=col] {
    background-color: #22495D;
    width: calc(100% - 55px);
  }

  [class=col-1] {
    background-color: #183949;
    text-align: center;
    width: 55px;

    i {
      margin-top: 11px;
      margin-left: -2px;

      @media only screen and (max-width: 767.98px) {
        margin-top: 28px;
      }
    }
  }
}

.TagsClosed {
  max-height: 30px;
  overflow: hidden;
}
