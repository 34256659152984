.Subtitle {
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 1rem;
  text-align: center;

  @media only screen and (max-width: 767.98px) {
    padding-bottom: 0;
  }
}
