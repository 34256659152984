.DroneDetection {
  padding-top: 3rem;
  background-image: url('../../images/contact-form-ci-bg.svg');
  background-position: top center;
  background-repeat: no-repeat;

  @media only screen and (max-width: 767.98px) {
    padding-top: 1rem;
  }

  .Intro {
    .Card {
      background-color: #132735;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.141176);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 14rem;
      margin: 0 auto 3rem;
      padding: 2.5rem 1.5rem;
      border-radius: 3px;

      img {
        margin: 0;
      }
    }

    p {
      text-align: center;
      color: #ffffff;
      font-size: 26px;

      strong {
        color: #FFCE48;
      }
    }
  }

  .Element {
    h2 {
      font-size: 54px;
      font-weight: 800;
      color: #FFCE48;
    }

    p {
      font-size: 20px;
      color: #ffffff;

      a {
        color: #ffffff;
        font-weight: normal;
      }
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .Reverse {
    div {
      &:first-child {
        @media only screen and (max-width: 767.98px) {
          order: 2;
        }
      }

      &:last-child {
        @media only screen and (max-width: 767.98px) {
          order: 1;
        }
      }
    }
  }
}
