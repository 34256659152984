.Hexagons {
  border: 1px solid transparent;
  left: 0;
  margin: 266px 0 280px 0;
  position: relative;
  top: 0;

  @media only screen and (max-width: 1399px) {
    margin: 224px 0;
    transform: scale(0.85);
  }

  @media only screen and (max-width: 1199px) {
    margin: 190px 0;
    transform: scale(0.725);
  }

  @media only screen and (max-width: 991px) {
    margin: 390px 0 340px 0;
  }

  @media only screen and (max-width: 575px) {
    margin: 350px 0;
    transform: scale(0.7);
  }
}

.BaseHexagon {
  position: absolute;

  left: calc(50% - 108px);
  top: -108px;
}

.Cybersecurity {
  [class=hexa-border] {
    background: #43BC96;

    [class=hexa-bg] {
      background: #335C6E;
    }
  }

  h3 {
    color: #FFCE48;
  }

  img {
    height: 19px;
  }
}

.Hexagon1 {
  @extend .BaseHexagon;
  @extend .Cybersecurity;

  transform: translate(-480px, -182px);

  @media only screen and (max-width: 991px) {
    transform: translate(-160px, -458px);
  }
}

.Hexagon2 {
  @extend .BaseHexagon;
  @extend .Cybersecurity;

  transform: translate(-480px, 0px);

  @media only screen and (max-width: 991px) {
    transform: translate(-160px, -276px);
  }
}

.Hexagon3 {
  @extend .BaseHexagon;
  @extend .Cybersecurity;

  transform: translate(-320px, -91px);

  @media only screen and (max-width: 991px) {
    transform: translate(0px, -368px);
  }
}

.Hexagon4 {
  @extend .BaseHexagon;
  @extend .Cybersecurity;

  transform: translate(-160px, 0px);

  @media only screen and (max-width: 991px) {
    transform: translate(160px, -276px);
  }
}

.Hexagon5 {
  @extend .BaseHexagon;

  transform: translate(160px, -182px);

  [class=hexa-border] {
    background: #43BC96;

    [class=hexa-bg] {
      background: #335C6E;

      h3 {
        color: #FFCE48;
      }
    }
  }

  img {
    height: 22px !important;
  }

  @media only screen and (max-width: 991px) {
    transform: unset;
  }
}

.Biometrics {
  [class=hexa-border] {
    background: #43BC96;

    [class=hexa-bg] {
      background: #335C6E;

      h3 {
        color: #FFCE48;
      }
    }
  }
}

.Hexagon6 {
  @extend .BaseHexagon;
  @extend .Biometrics;

  transform: translate(160px, 182px);

  @media only screen and (max-width: 991px) {
    transform: translate(-160px, 276px);
  }
}

.Hexagon7 {
  @extend .BaseHexagon;
  @extend .Biometrics;

  transform: translate(320px, 91px);

  @media only screen and (max-width: 991px) {
    transform: translate(0px, 368px);
  }
}

.Hexagon8 {
  @extend .BaseHexagon;
  @extend .Biometrics;

  transform: translate(480px, 182px);

  img {
    height: 18px !important;
  }

  @media only screen and (max-width: 991px) {
    transform: translate(160px, 276px);
  }
}
