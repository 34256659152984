.Award3 {
  max-width: 110px !important;
}

.Award4 {
  max-width: 145px !important;
}

.Award9 {
  max-width: 135px !important;
}
