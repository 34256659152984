.Healthcare {
  background-image: url('../../images/contact-form-ci-bg.svg');
  background-position: top center;
  background-repeat: no-repeat;

  .healthIntro {
    p {
      text-align: center;
      font-size: 21px;

      a {
        margin-right: 6px;
      }
    }
  }

  h2, p {
    color: #ffffff;
  }

  a {
    display: inline-block;
    cursor: pointer;
  }
}
