.Navbar {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  padding: 0;

  @media only screen and (max-width: 991.98px) {
    background: rgba(0, 0, 0, 0.75);
  }

  [class=container] {
    position: relative;
  }

  .Cursor {
    position: absolute;
    z-index: 50;
    left: 11px;
    top: 17px;
    transform: scale(0.23);

    @media only screen and (max-width: 1200px) {
      left: 11px;
    }

    @media only screen and (max-width: 991.98px) {
      top: 4px;
    }
  }

  [class~=nav-item] {
    [class=nav-link] {
      color: #ffffff !important;
      font-size: 15px;
      padding: 25px 5px;
      font-weight: 700;
      text-transform: uppercase;

      &:hover {
        color: #3BB1E6 !important;
        text-decoration: none;
      }

      @media only screen and (max-width: 991.98px) {
        font-size: 20px;
        padding: 5px 0;
        color: #ffce48 !important;
      }
    }

    [class~=active] {
      color: #3BB1E6 !important;
      padding: 25px 5px;
      font-size: 15px;
      font-weight: 500;
      text-transform: uppercase;
      text-decoration: none;

      @media only screen and (max-width: 991.98px) {
        padding: 5px 0;
      }
    }

    > ul {
      list-style-type: none;
      padding-left: 0.5rem;

      > li {
        text-transform: none;

        a {
          color: #ffffff !important;
          font-size: 15px;
          font-weight: 500;

          &:hover {
            background: none;
            color: #3BB1E6 !important;
            text-decoration: none;
          }
        }
      }
    }

    .ContactButton {
      border: 1px solid #ffffff;
      border-radius: 999px;
      color: #ffffff !important;
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;
      height: 34px;
      padding: 6px;
      text-align: center;
      text-transform: uppercase;
      width: 162px;

      &:hover {
        background-color: #ffffff;
        color: #222222 !important;
      }

      @media only screen and (max-width: 1441px) {
        margin-right: 1.25rem;
      }

      @media only screen and (max-width: 1230px) {
        margin-right: 1.5rem;
      }

      @media only screen and (max-width: 1050px) {
        margin-right: 2.25rem;
      }
    }

    .ContactButton[class~=active] {
      color: #ffffff !important;
      font-size: 14px !important;
      text-decoration: none !important;
    }

    ul {
      display: none;
      position: absolute;
      color: #ffffff;
      background: rgba(0, 0, 0, 0.75);
      -webkit-backdrop-filter: blur(4px);
      left: 0;
      top: 72px;
      padding: 15px 35px 25px;
      min-width: 20rem;

      @media only screen and (max-width: 991.98px) {
        display: block;
        position: static;
        right: 0;
        width: 100%;
        background: transparent;
        min-height: unset;
      }

      div {
        width: 50%;
        display: inline-flex;
        justify-content: space-between;
        align-items: flex-start;

        @media only screen and (max-width: 991.98px) {
          width: 100%;
          display: block;
        }

        & > li {
          width: 50%;

          @media only screen and (max-width: 991.98px) {
            width: 100%;
          }
        }

        span {
          margin-bottom: 1rem;
          display: block;
          color: #d2d2d2;
          font-size: 15px;
        }
      }

      li {
        h5 {
          margin: 5px 0;
          color: #3BB1E6;
          font-size: 21px;
          font-weight: 700;
          text-transform: uppercase;

          @media only screen and (max-width: 991.98px) {
            font-size: 18px;
          }
        }

        a {
          display: block;
          text-decoration: none;
          color: #ffffff;
          font-size: 18px;
          font-weight: 400;
          padding-bottom: 5px;
          transition: all .3s ease;
          padding: 0;

          @media only screen and (max-width: 991.98px) {
            font-size: 16px;
          }

          &:hover {
            color: #3BB1E6;
            transition: all .3s ease;

            &+span {
              color: #3BB1E6;
              transition: all .3s ease;
            }
          }
        }
      }
    }

    &:hover {
      ul {
        display: block;
      }
    }
  }
}
