@import 'bootstrap/scss/bootstrap-utilities';

.Tag {
  @extend .text-uppercase;
  @extend .text-nowrap;
  @extend .fw-bold;
  padding: 0 !important;
  font-size: 13px !important;

  &:hover {
    color: #49295f;
    text-decoration: underline;
  }

  &:after {
    display: none;
  }
}
