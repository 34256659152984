.PageTitle {
  color: #ffffff;
  font-family: 'Karla', sans-serif;
  font-size: 60px;
  font-weight: 800;
  text-align: center;
  line-height: 80px;
  background: linear-gradient(90deg, #3BB1E6 0%, #43DDFB 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 1rem 0;

  @media only screen and (max-width: 575.98px) {
    font-size: 42px;
    line-height: 50px;
  }
}
