.Journey {
  color: #ffffff;
  margin-bottom: 5rem;

  @media only screen and (max-width: 767.98px) {
    margin-bottom: 0rem;
  }

  .Right {
    text-align: right;

    @media only screen and (max-width: 767.98px) {
      text-align: center;
    }
  }

  .Left {
    text-align: left;

    @media only screen and (max-width: 767.98px) {
      text-align: center;
    }
  }

  h3, p {
    margin-bottom: 5px;
  }

  .Title {
    color: #FFCE48;
    font-weight: 700;
  }

  .Description {
    font-size: 18px;
  }

  .timelineItem {
    position: relative;

    .TimelineLine {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 5px;
      background-color: #ffffff;
    }

    .TimelineDot {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 15px;
      height: 15px;
      background-color: #ffffff;
      border-radius: 50%;
      z-index: 1;

      @media only screen and (max-width: 767.98px) {
        display: none;
      }
    }
  }
}
