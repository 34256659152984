.FaIcon {
  .fa-circle {
    color: #4E7088;
  }

  .fa-inverse {
    color: #132735;
  }

  &:hover {
    .fa-circle {
      color: #ffffff;
    }
  }
}