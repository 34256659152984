.Technology {
  margin-bottom: 4rem;

  .TechnologyBox {
    display: flex;
    padding: 50px;
    background-color: #132735;
    border-bottom: 1px solid #979797;

    @media only screen and (max-width: 991.98px) {
      display: block;
      text-align: center;
    }

    &:last-child {
      border-bottom: 0;
    }

    .TechnologyBadge {
      display: block;
      width: 12%;

      @media only screen and (max-width: 991.98px) {
        width: 100%;
        text-align: center;
      }

      span {
        background-image: linear-gradient(90deg,
          #319475, #43BC96);
        border-radius: 10px;
        width: 95px;
        height: 95px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 991.98px) {
          margin: 0 auto;
        }

        img {
          display: block;
          max-width: 40%;
          max-height: 40%;
        }

        &+a {
          margin-top: 1rem;
          margin-left: 2px;
        }
      }
    }

    .TechnologyText {
      width: 87%;

      @media only screen and (max-width: 991.98px) {
        width: 100%;
        padding-top: 2rem;
      }

      h3 {
        font-weight: 800;
        font-size: 22px;
        line-height: 26px;
        color: #f6cb49;
        margin-bottom: 2rem;
      }

      p {
        color: #ffffff;
      }

      ul {
        padding: 0 0 0 1.5rem;
        margin: 2rem 0;

        li {
          color: #ffffff;
          list-style: none;
          position: relative;
          margin-bottom: 3px;

          &:before {
            content: '';
            background-image: url('../../images/list-item.svg');
            background-size: 100%;
            width: 12px;
            height: 16px;
            display: block;
            position: absolute;
            left: -22px;
            top: 4px;
          }
        }
      }
    }

    .OnlyDesktop {
      @media only screen and (max-width: 991.98px) {
        display: none;
      }
    }

    .OnlyMobile {
      display: none;

      @media only screen and (max-width: 991.98px) {
        display: inline-block;
      }
    }
  }
}
