.Resources {
  .SmallTitle {
    color: #ffffff;
    text-align: center;
    margin-top: -2rem;
    margin-bottom: 3rem;
    font-weight: 300;
    font-size: 22px;
    line-height: 26px;
  }

  .ResourcesSlide {
    position: relative;
    height: 30rem;
    margin-bottom: 2rem;

    @media only screen and (max-width: 991.98px) {
      height: auto;
    }

    div {
      &:first-child {
        background: #132735;
        box-shadow: 0px 2px 30px 3px rgba(0, 0, 0, 0.197279);
        padding: 30px 50px 30px 30px;
        width: 50%;
        position: absolute;
        bottom: -1rem;
        left: 0;
        z-index: 50;

        @media only screen and (max-width: 991.98px) {
          position: static;
          width: 100%;
          text-align: center;
        }
      }

      &:last-child {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 20;
        max-width: 80%;

        @media only screen and (max-width: 991.98px) {
          display: none;
        }
      }

      h3 {
        font-size: 26px;
        font-weight: 900;
        text-decoration: none;
        color: #FFCE48;
        transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
        display: -webkit-box;
        margin-bottom: 1rem;

        @media only screen and (max-width: 991.98px) {
          display: block;
          text-align: center;
        }
      }

      p {
        display: -webkit-box;
        margin-bottom: 1rem;
        color: #ffffff;

        @media only screen and (max-width: 991.98px) {
          display: block;
          text-align: center;
        }
      }
    }
  }
}

.CaseStudies {
  .Card {
    background-color: #132735;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.141176);
    margin: 0 auto 3rem;
    display: block;
    text-decoration: none;
    padding: 1.5rem;
    border-radius: 3px;
    min-height: 14.5rem;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    &:before {
      position: absolute;
      top: 0;
      left: -75%;
      z-index: 2;
      display: block;
      content: "";
      width: 50%;
      height: 100%;
      background: linear-gradient(to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.3) 100%);
      transform: skewX(-25deg);
    }

    &:hover {
      transform: scale(1.035);
      transition: all 0.3s ease-in-out;

      &:before {
        animation: shine 0.75s;
      }
    }

    h3 {
      font-size: 32px;
      font-weight: 800;
      color: #FFCE48;
      text-decoration: none;
    }

    p {
      font-size: 16px;
      color: #ffffff;
      text-decoration: none;
    }
  }
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}
