.ClinicalNeurosciences {
  padding-top: 3rem;
  background-image: url('../../images/contact-form-ci-bg.svg');
  background-position: top center;
  background-repeat: no-repeat;

  @media only screen and (max-width: 992px) {
    text-align: center !important;
  }

  @media only screen and (max-width: 767.98px) {
    padding-top: 1rem;
  }

  .Intro {
    .Card {
      background-color: #132735;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.141176);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 14rem;
      margin: 0 auto 3rem;
      padding: 1.5rem 1.5rem 2.5rem;
      border-radius: 3px;

      img {
        margin: 0;
        width: 8rem;
      }
    }

    p {
      text-align: center;
      color: #ffffff;
      font-size: 26px;

      strong {
        color: #FFCE48;
      }

      a {
        color: #ffffff;
        font-weight: normal;
      }
    }
  }

  .Element {
    h2 {
      font-size: 54px;
      font-weight: 800;
      color: #FFCE48;
    }

    h3 {
      font-size: 44px;
      font-weight: 800;
      color: #FFCE48;
    }

    p {
      font-size: 20px;
      color: #ffffff;
    }

    img {
      display: block;
      width: 100%;
    }

    iframe {
      display: block;
      width: 100%;
      height: 20rem;

      @media only screen and (max-width: 768px) {
        height: 16rem;
      }
    }

    .Publication {
      margin-top: 4.25rem;

      @media only screen and (max-width: 768px) {
        margin: 2rem auto;
        width: 75%;
      }
    }
  }

  .Images {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    @media only screen and (max-width: 992px) {
      display: block;
    }

    img {
      display: block;
      width: 24rem;
      max-width: 100%;

      @media only screen and (max-width: 1366px) {
        width: 20rem;
      }

      @media only screen and (max-width: 1280px) {
        margin: 0 auto 2rem;
        width: 25rem;
      }
    }
  }

  .Motion {
    width: 550px !important;
    max-width: 100%;
    margin: 2rem auto 4rem !important;
  }

  .Reverse {
    div {
      &:first-child {
        @media only screen and (max-width: 992.98px) {
          order: 2;
          text-align: center;
        }
      }

      &:last-child {
        text-align: right;

        @media only screen and (max-width: 992.98px) {
          order: 1;
          text-align: center;
        }
      }
    }
  }
}
