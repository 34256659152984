.SectionTitle {
  color: #ffffff;
  font-family: 'Karla', sans-serif;
  font-size: 54px;
  font-weight: 800;
  text-align: center;
  line-height: 80px;
  background: linear-gradient(90deg, #3BB1E6 0%, #43DDFB 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media only screen and (max-width: 575.98px) {
    font-size: 42px;
    line-height: 50px;
  }
}

p {
  a {
    text-decoration: none;
    transition: all .15s ease;
    color: #20b9f5;
    display: inline-flex;
    font-size: inherit;
    font-weight: 700;
    line-height: calc(.875rem + 10px);
    min-width: auto;
    padding: 0;
    position: relative;
    z-index: 2;

    &:after {
      width: 0;
      transition: all .25s ease;
    }

    &:hover {
      text-decoration: none;
      color: #20b9f5;
      transition: all .25s ease;

      &:after {
        transition: all .25s ease;
        background-color: rgba(32, 185, 245, .35);
        bottom: .25rem;
        content: "";
        height: 21px;
        left: 0;
        bottom: 0;
        position: absolute;
        width: 100%;
        z-index: -1;
      }
    }

    &:active {
      color: grey;

      &:after {
        width: 7%;
      }
    }
  }
}
