.Hero {
  padding: 50px 0;
  overflow: hidden;
  background:
    linear-gradient(
      to left,
      #ffce48 0%,
      #6ec3f4 45%,
      #6ec3f4 55%,
      #54C19F 100%
    )
    left
    bottom
    transparent
    no-repeat;
  background-size: 100% 5px;

  [class=row] {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 auto;

    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 120%;
    text-transform: uppercase;
    color: #ffffff;

    @media only screen and (max-width: 575.98px) {
      font-size: 32px;
    }
  }

  .Spinner {
    display: flex;
    width: 98%;
    float: right;
    position: relative;
    background-image:
      repeating-linear-gradient(0deg, #43BC96, #43BC96 4px, transparent 4px, transparent 30px, #43BC96 30px),
      repeating-linear-gradient(90deg, #43BC96, #43BC96 4px, transparent 4px, transparent 30px, #43BC96 30px),
      repeating-linear-gradient(180deg, #43BC96, #43BC96 4px, transparent 4px, transparent 30px, #43BC96 30px),
      repeating-linear-gradient(270deg, #43BC96, #43BC96 4px, transparent 4px, transparent 30px, #43BC96 30px);
    background-size: 3px 100%, 100% 3px, 3px 100% , 100% 3px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 767.98px) {
      margin-top: 3rem;
    }

    @media only screen and (max-width: 575.98px) {
      display: block;
    }

    .SpinnerInner {
      position: relative;

      &:first-child {
        display: flex;
        align-items: center;
        width: 55%;
        padding: 32px;
        z-index: 7;

        @media only screen and (max-width: 575.98px) {
          padding: 25px 25px 0;
          width: 100%;
          text-align: center;
        }
      }

      &:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
        color: #ffffff;
        margin: 0.75rem 0 0.75rem -10rem;
        padding: 20px 35px 20px 140px;
        z-index: 5;

        @media only screen and (max-width: 575.98px) {
          width: 100%;
          padding: 15px 55px 25px;
          margin: 0;
        }

        img {
          width: 100%;
        }
      }

      h3 {
        font-weight: 800;
        font-size: 36px;
        line-height: 120%;
        color: #FFCE48;
        margin: 0;

        @media only screen and (max-width: 1189.98px) {
          font-size: 24px;
        }

        @media only screen and (max-width: 575.98px) {
          font-size: 36px;
          display: block;
          margin: 0 auto;
        }
      }

      h4 {
        font-size: 2rem;

        @media only screen and (max-width: 1189.98px) {
          font-size: 1.5rem;
        }

        @media only screen and (max-width: 575.98px) {
          font-size: 26px;
        }
      }

      img {
        margin-right: 15px;
        width: 120px;

        @media only screen and (max-width: 575.98px) {
          width: 60px;
        }
      }
    }
  }
}
