.Nurology {
  margin: 4rem 0;
  color: #ffffff;

  .TabItem {
    a {
      background-color: transparent;
      cursor: pointer;
      color: #ffffff;

      &.active {
        background-color: #3BB1E6 !important;
      }
    }
  }

  .markdown {
    margin: 3rem 0 2rem;
    display: block;
  }

  table {
    color: #ffffff;

    th, td {
      text-align: left;
      color: #ffffff !important;
    }
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &,maxwidth {
      img {
        margin: 1rem auto;
      }
    }

    img {
      display: block;
      max-width: 100%;
    }

    iframe {
      display: block;
      max-width: 100%;
      width: 83%;
      height: 80rem;
      margin: 5rem auto 0;
      overflow: hidden;
    }
  }
}
