.nav-tabs {
  display: flex;
  justify-content: space-between;
  border: 0;

  .nav-item {
    flex: 1;
    text-align: center;
    background-color: #1B1C1E;

    @media only screen and (max-width: 767.98px) {
      width: 100%;
      flex: unset;
    }
  }

  .nav-link {
    width: 100%;
    padding: 20px 40px;
    border: 0;
    border-radius: 0;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;

    &.active {
      color: #1B1C1E;
      background-color: #3BB1E6;
    }
  }
}

.tab-content {
  background-color: #132735;
  padding: 40px;
  color: #ffffff;

  & > .active {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 1rem;
      width: 5rem;

      @media only screen and (max-width: 767.98px) {
        display: none;
      }
    }
  }
}

.box {
  color: #ffffff;
  background-color: #132735;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.OnlyDesktop {
  @media only screen and (max-width: 991.98px) {
    display: none;
  }
}

.OnlyMobile {
  display: none;

  @media only screen and (max-width: 991.98px) {
    display: inline-block;
  }
}