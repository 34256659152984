.LawEnforcement {
  background-image: url('../../images/contact-form-ci-bg.svg');
  background-position: top center;
  background-repeat: no-repeat;

  .LawIntro {
    p {
      color: #ffffff;
      text-align: center;
      font-size: 21px;

      a {
        margin-right: 6px;
        display: inline-block;
        cursor: pointer;
      }
    }

    a {
      display: inline-block;
    }
  }
}
