.Header {
  background-color: #1D3241;
  background-image: url('./header-ci-bg.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;

  @media only screen and (max-width: 575.98px) {
    background-size: cover;
  }

  .Container {
    padding-top: 64px;

    @media only screen and (max-width: 575.98px) {
      padding-top: 48px;
    }
  }
}
