.Team {
  @media only screen and (max-width: 767.98px) {
    width: 85%;
    margin: 2rem auto;
  }

  img {
    display: block;
    width: 100%;
    margin-bottom: 12px;
  }

  h4 {
    font-size: 21px;
    font-weight: 800;
    line-height: 25px;
    color: #ffffff;

    span {
      background: linear-gradient(90deg, #3BB1E6 0%, #43DDFB 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  p {
    color: #ffffff;
  }
}
