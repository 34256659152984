html,
body,
#root {
  min-height: 100vh;
}

body {
  font-family: 'Karla', sans-serif !important;
  overflow-y: scroll;

  h1, h2, h3, h4, h5, h6, p, strong, a {
    -webkit-tap-highlight-color: #20b9f5;
    -webkit-touch-callout: default;
    -webkit-user-select: text;
    user-select: text;

    &::selection {
      background: #20b9f5;
      color: #1D3241;
    }
  }
}

#root {
  display: flex;
  flex-direction: column;
}

main {
  background-color: #1D3241;
}

section {
  p {
    @media only screen and (max-width: 575.98px) {
      font-size: 18px !important;
    }
  }
}

.business-card {
  background-color: #132735;
  box-shadow: 0 1px 5px #00000099;
  border-radius: 10px;
  color: #ffffff;
  padding: 1.75rem;
  display: flex;

  &.twofa {
    background: #132735 url('../images/logo-small-2fa.svg') 11% -15%;
    background-size: 40%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 991.98px) {
      background-size: 80%;
      background-position: 50% -25%;
    }
  }

  &.sentinel {
    background: #132735 url('../images/logo-small-sentinel.svg') 11% -15%;
    background-size: 40%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 991.98px) {
      background-size: 80%;
      background-position: 50% -25%;
    }
  }

  &.captcha {
    background: #132735 url('../images/logo-small-captcha.svg') 11% -15%;
    background-size: 40%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 991.98px) {
      background-size: 80%;
      background-position: 50% -25%;
    }
  }

  &.anticheat {
    background: #132735 url('../images/logo-small-anticheat.svg') 11% -15%;
    background-size: 34%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 991.98px) {
      background-size: 80%;
      background-position: 50% -25%;
    }
  }

  &.sw {
    background: #132735 url('../images/logo-small-sw.svg') 11% -15%;
    background-size: 40%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 991.98px) {
      background-size: 80%;
      background-position: 50% -25%;
    }
  }

  &.ms {
    background: #132735 url('../images/logo-small-ms.svg') 11% -15%;
    background-size: 40%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 991.98px) {
      background-size: 80%;
      background-position: 50% -25%;
    }
  }

  &.ci {
    background: #132735 url('../images/logo-small-ci.svg') 11% -15%;
    background-size: 40%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 991.98px) {
      background-size: 80%;
      background-position: 50% -25%;
    }
  }

  &.motion {
    background: #132735 url('../images/logo-small-motionanalysis.svg') 11% 125%;
    background-size: 40%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 991.98px) {
      background-size: 80%;
      background-position: 50% -25%;
    }
  }

  @media only screen and (max-width: 991.98px) {
    display: block;
  }

  div {
    width: 50%;

    @media only screen and (max-width: 991.98px) {
      width: 100%;
      text-align: center;
    }

    &:first-child {
      display: flex;
      flex-direction: column;
      padding-right: 2.5rem;

      @media only screen and (max-width: 991.98px) {
        display: block;
        padding-right: 0;
      }
    }

    img {
      width: 100%;
      max-width: 100%;

      @media only screen and (max-width: 991.98px) {
        margin-top: 2rem;
      }
    }
  }

  .ButtonPrimary {
    width: 12rem;

    &.motionAnalysis {
      margin-top: auto;
    }
  }

  h3 {
    color: #FFCE48;
    font-weight: 700;
  }

  ul {
    padding: 0 0 0 1rem;

    li {
      position: relative;

      &:before {
        content: '';
        background-image: url('../images/list-item.svg');
        background-size: 100%;
        width: 12px;
        height: 16px;
        display: block;
        position: absolute;
        left: -20px;
        top: 4px;
      }

      @media only screen and (max-width: 991.98px) {
        list-style: none;
        text-align: left;
      }
    }
  }

  .iframe-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;

    @media only screen and (max-width: 991.98px) {
      margin-top: 2rem;
    }

    iframe {
      position: absolute;
      z-index: 50;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.mainlink {
  text-decoration: none;
  transition: all .15s ease;
  color: #20b9f5;
  display: inline-flex;
  font-size: inherit;
  font-weight: 700;
  line-height: calc(.875rem + 10px);
  min-width: auto;
  padding: 0;
  position: relative;
  z-index: 2;

  &:after {
    width: 0;
    transition: all .25s ease;
  }

  &:hover {
    text-decoration: none;
    color: #20b9f5;
    transition: all .25s ease;

    &:after {
      transition: all .25s ease;
      background-color: rgba(32,185,245,.35);
      bottom: .25rem;
      content: "";
      height: 21px;
      left: 0;
      bottom: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }

  &:active {
    color: grey;

    &:after {
      width: 7%;
    }
  }
}

footer {
  margin-top: auto;
}
