.Solutions {
  margin-top: 5rem;

  p {
    a {
      text-decoration: none;
      color: #3BB1E6;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .ListItem {
    background: #132735;
    box-shadow: 0px 2px 30px 3px rgba(0, 0, 0, 0.197279);
    border-radius: 4px;
    padding-top: 2rem;
    border: 1px solid rgba(0, 0, 0, .125);
    width: 45%;
    margin-bottom: 3rem;

    @media only screen and (max-width: 992px) {
      width: 80%;
    }

    .ListItemInner {
      width: 50%;

      @media only screen and (max-width: 575.98px) {
        width: 100%;
      }

      & > div {
        height: 35px;

        & > div {
          width: 3rem;
          height: 100%;

          img {
            width: 35px;
            height: 35px;
          }
        }
      }
    }

    h3 {
      color: #FFCE48;
      font-size: 28px;
      font-weight: 800;
      line-height: 25px;
    }

    & > div {
      p {
        font-weight: 600;
        font-size: 19px;
      }
    }
  }
}
