.BookMeeting {
  background-color: #1d3241;
  border: 3px solid #3BB1E6;
  border-radius: 6px;
  box-sizing: border-box;
  color: #ffffff;
  margin: 3rem auto 0 auto;
  padding-bottom: 2rem;
  text-align: center;
  width: calc(min(350px, 90vw));

  h4 {
    background-color: #3BB1E6;
    color: #ffffff;
    padding: 0.5rem 0;
  }

  p {
    font-size: 14pt;
    padding: 1rem 1rem;
  }

  a {
    font-size: 1rem;
    font-weight: 700;
    width: calc(min(120px, 100%));
  }
}
