.Testimonial {
  background: #132735;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 3px solid #ffffff;
  height: 460px;
  margin: 47px auto 10px auto;
  padding: 0 22px;
  border-radius: 5px;
  position: relative;
  text-align: center;
  width: 330px;

  @media only screen and (max-width: 1200px) {
    width: 302px;
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
  }

  .Image {
    border-radius: 100%;
    height: 94px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    top: -47px;
    width: 94px;

    img {
      background: #D9D9D9;
      border: 3px solid #ffffff;
      border-radius: 100%;
      height: 86px;
      margin: 0 auto;
      width: 86px;
    }
  }

  .Text {
    font-family: 'Karla', sans-serif;
    margin-top: -2rem;
    position: relative;

    h5 {
      color: #FFCE48;
      font-size: 21px;
      font-weight: 800;
      line-height: 25px;
      margin-bottom: 5px;

      @media only screen and (max-width: 768px) {
        font-size: 17px;
      }
    }

    h6 {
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      height: 2.5rem;
      margin-top: 8px;

      @media only screen and (max-width: 768px) {
        font-size: 15px;
      }
    }

    span {
      background: url('./images/quote-icon.svg') no-repeat 50%;
      display: block;
      height: 4rem;
      margin: 14px 0;
      width: 100%;

      @media only screen and (max-width: 768px) {
        margin: 18px 0 12px;
      }
    }

    p {
      color: #ffffff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: center;

      @media only screen and (max-width: 768px) {
        font-size: 14px !important;
      }
    }
  }
}
