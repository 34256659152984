.Video {
  p {
    color: #ffffff;
    font-size: 20px;
  }
}

.videoContainer {
  position: relative;

  iframe {
    width: 100%;
    margin: 30px 0 50px;
    height: 30rem;
    position: relative;
    z-index: 50;

    @media only screen and (max-width: 991.98px) {
      height: 20rem;
    }

    @media only screen and (max-width: 767.98px) {
      height: 15rem;
    }
  }

  &:before {
    content: '';
    position: absolute;
    background: #3BB1E6;
    width: 50rem;
    height: 28rem;
    z-index: 10;
    top: 20px;
    right: -8px;

    @media only screen and (max-width: 1480px) {
      right: -8px;
    }

    @media only screen and (max-width: 1400px) {
      width: 45rem;
      height: 25rem;
      right: -8px;
      top: 3.25rem;
    }

    @media only screen and (max-width: 1199.98px) {
      width: 37rem;
      height: 21rem;
      right: -0.5rem;
      top: 5.25rem;
    }

    @media only screen and (max-width: 992px) {
      width: 28rem;
      height: 15.5rem;
      right: -0.5rem;
      top: 3.25rem;
    }

    @media only screen and (max-width: 768px) {
      width: 26rem;
      height: 15.5rem;
      right: 2.25rem;
      top: 1rem;
    }

    @media only screen and (max-width: 575.98px) {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    width: 50rem;
    height: 28rem;
    z-index: 10;
    bottom: 45px;
    left: -10px;
    padding: 2px;
    background: #3BB1E6;
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;

    @media only screen and (max-width: 1480px) {
      left: -10px;
    }

    @media only screen and (max-width: 1400px) {
      width: 45rem;
      height: 25rem;
      left: -8px;
      bottom: 5rem;
    }

    @media only screen and (max-width: 1199.98px) {
      width: 38rem;
      height: 21rem;
      left: -0.75rem;
      bottom: 6.85rem;
    }

    @media only screen and (max-width: 992px) {
      width: 28rem;
      height: 15rem;
      left: -0.75rem;
      bottom: 4.85rem;
    }

    @media only screen and (max-width: 768px) {
      width: 26rem;
      height: 14rem;
      left: 2rem;
      bottom: 2.85rem;
    }

    @media only screen and (max-width: 575.98px) {
      display: none;
    }
  }
}
