@import 'bootstrap/scss/bootstrap-utilities';

.Post {
  font-size: 1.125rem;

  h1 {
    font-size: 3rem;
    font-weight: 900;
    color: #FFCE48;
    margin-bottom: 1.25rem;

    @media screen and (max-width: 992px) {
      font-size: 2rem;
    }
  }

  h2 {
    color: #FFCE48;
    font-size: 2.5rem;
    font-weight: 700;
  }

  h3 {
    color: #FFCE48;
    font-size: 2.15rem;
    font-weight: 700;
  }

  h4 {
    color: #ffffff;
    font-size: 1.85rem;
    font-weight: 700;
  }

  p {
    margin: 1rem 0;
    color: #ffffff;
  }

  li {
    color: #ffffff;
  }

  iframe {
    max-width: 100%;
  }

  figure {
    margin: 2.5rem 0;
    text-align: center;
  }

  figcaption {
    text-align: center;
    font-size: small;
    color: #ffffff;

    a {
      font-size: small;
      color: #ffffff;
      font-weight: 400;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  hr {
    background-color: #ffffff;
  }

  b, strong {
    font-weight: 900;
  }

  a {
    text-decoration: none;
    transition: all .15s cubic-bezier(.4,0,.2,1);
    color: #20b9f5;
    display: inline-flex;
    font-size: 18px;
    font-weight: 700;
    line-height: calc(.875rem + 10px);
    min-width: auto;
    padding: 0;
    display: inline;
    position: relative;
    z-index: 2;

    &:after {
      width: 0;
    }

    &:hover {
      color: #ffffff;
      text-decoration: none;

      &:after {
        transition: all .25s cubic-bezier(.4,0,.2,1);
        background-color: rgba(32,185,245,.25);
        bottom: 0;
        content: "";
        height: 22px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: -1;

        @media only screen and (max-width: 575.98px) {
          height: 20px;
        }
      }
    }

    &:active {
      color: grey;

      &:after {
        width: 7%;
      }
    }
  }

  [class~=has-text-align-center] {
    text-align: center !important;
  }

  [class~=wp-block-buttons] {
    margin: 3rem 0;
    text-align: center;
  }

  [class~=wp-block-button__link] {
    padding: 16px 30px;
    background-color: #3bb1e6;
    background-image: linear-gradient(45deg,#3bb1e6,#43ddfb,#3bb1e6,#1d3241);
    background-position: 0;
    background-repeat: no-repeat;
    background-size: 300%;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .141176);
    border-radius: 999px;
    font-family: 'Karla';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    transition: all .3s ease;

    &:hover {
      color: #FFFFFF;
      background-color: #0aa7e5;
      background-position: 50%;
      border-color: #0a9ed8;
      transform: scale(1.02);
      box-shadow: 0 4px 8px 0 rgba(0, 166, 230, .28);
    }

    &:active {
      box-shadow: none;
      color: rgba(255, 255, 255, .75);
      transition-duration: .1s;
      transform: scale(0.99) translateY(1px);
    }

    &:after {
      content: '';
      display: none;
    }
  }
}

.PostDots {
  @extend .w-100;
  @extend .me-2;

  background: transparent;
  background-image: radial-gradient(#1cb8dc 2px, transparent 0);
  background-size: 20px 20px;
  background-position: center center;
  background-repeat: repeat-x;
}

.PostRow {
  color: #ffffff;
  @extend .d-flex;
  @extend .flex-row;
  @extend .justify-content-between;
  @extend .mb-3;
}
