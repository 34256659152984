footer {
  background-color: #132735;
  border-top: 7px solid #3bb1e6;
}

.Footer {
  .FooterLogo {
    width: 400px;
    max-width: 100%;
    display: block;
  }

  .Info {
    font-size: 16px;
    color: #57636C;
    margin-bottom: 1.5rem;

    a {
      color: #57636C;
      text-decoration: none;

      &:hover {
        color: #ffffff;
      }
    }
  }

  .Offices {
    span {
      display: block;
      font-size: 16px;
      color: #57636C;
    }
  }

  .Twitter {
    background-color: #4E7088;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 2em;
    vertical-align: middle;
    width: 37px;
    height: 37px;
    margin-right: 12px !important;

    img {
      width: 40%;
      display: block;
    }

    &:hover {
      background-color: #ffffff;
    }
  }

  .MenuWrapper {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  .MenuItem {
    display: inline-block;
    margin-left: 25px;

    @media only screen and (max-width: 768px) {
      display: block;
      width: 100%;
      padding: 0 0.75rem;
      margin: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    h3 {
      margin-bottom: 18px;

      @media only screen and (max-width: 768px) {
        padding-top: 1.5rem;
      }

      a {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 26px;
        color: #3BB1E6;
      }
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        margin-bottom: 14px;

        span {
          text-transform: uppercase;
          font-size: 20px;
          color: #3BB1E6;
          margin-bottom: 12px;
          display: block;
        }

        a {
          display: block;
          margin-bottom: 8px;
          text-decoration: none;
          color: #ffffff;

          &:hover {
            color: #3BB1E6;
          }
        }
      }
    }
  }
}
