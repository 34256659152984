.Award {
  display: inline-flex;
  flex-direction: column;
  max-width: 320px;
  height: 100%;

  > div {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 170px;
    margin: 0 auto;

    @media only screen and (max-width: 575.98px) {
      max-width: 240px;
    }

    img {
      max-width: 170px;
    }
  }

  > p, a {
    color: #2eb1e7;
    font-size: 14px;
    text-align: center;
    text-decoration: none;

    @media only screen and (max-width: 767.98px) {
      word-break: break-word;
    }
  }

  a {
    &:hover {
      color: #ffffff;
    }
  }
}
