.Gdpr {
  padding: 1rem 0 4rem;
  background-position: top center;
  background-repeat: no-repeat;

  h1 {
    margin-top: 3rem;
  }

  h2, p, li {
    color: #ffffff;
  }

  h3 {
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 1.75rem;
  }

  h5 {
    color: #ffffff;
    font-weight: 700;
  }

  a {
    display: inline-block;
  }

  table {
    display: table;
    color: #ffffff;
    width: 100%;
    table-layout: fixed;
    border: 1px solid #ffffff;

    thead {
      text-align: center;

      th {
        border: 1px solid #ffffff;
        padding: 1rem;
      }
    }

    tbody {
      td {
        border: 1px solid #ffffff;
        vertical-align: top;
        padding: 1rem;
      }
    }
  }
}
