.ScrollToTop {
  position: fixed;
  z-index: 999;
  bottom: 20px;
  right: 20px;
  text-align: center;
  font-size: 20px;
  color: white;
  background-image: linear-gradient(108deg, #54C19F 5%, #ffce48 95%);
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  border: 0;
  outline: none;
  opacity: .45;
  transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &:hover {
    transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transform: scale(1.15) rotate(360deg);
    opacity: 1;
    box-shadow:
      2.2px 2.6px 2.7px rgba(0, 0, 0, 0.016),
      5.6px 6.7px 6.9px rgba(0, 0, 0, 0.022),
      11.5px 13.6px 14.2px rgba(0, 0, 0, 0.028),
      23.7px 28.1px 29.2px rgba(0, 0, 0, 0.034),
      65px 77px 80px rgba(0, 0, 0, 0.05)
    ;
  }

  &:active {
    transition: all .25s ease;
    transform: scale(0.99) rotate(360deg);
  }
}
