.Testimonials {
  background: rgb(29,50,65);
  background: linear-gradient(0deg, rgba(29,50,65,1) 20%, rgba(42,73,96,1) 20%, rgba(42,73,96,1) 62%, rgba(29,50,65,1) 62%);

  .container {
    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  .slick-slider {
    padding: 24px 0 84px 0;

    .slick-dots {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 36px;
      bottom: 1.5rem;

      li {
        width: 8px;
        height: 8px;
        border-radius: 100px;
        margin: 0 8px;
        transition: width .2s ease;
        background: #3BB1E6;

        &.slick-active {
          width: 45px;
        }

        button {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
