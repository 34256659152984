.Box {
  text-align: center;
  background-color: #132735;
  padding: 2rem;
  height: 100%;

  h3 {
    font-weight: 800;
    font-size: 56px;
    line-height: 120%;
    color: #FFCE48;
    margin: 0;
    height: 5rem;
  }

  p {
    text-align: center;
    color: #ffffff;
    font-size: 21px;
  }

  br {
    display: block;

    @media only screen and (max-width: 991.98px) {
      display: none;
    }
  }
}
